import { Box } from "@mui/material";
import  { useEffect, useState } from "react";
import { Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import ProductComboCard from "../ProductCard/productComboCard";
import medicinePlaceHolder from "../../../assets/products.png";
import { Img } from "react-image";

type ProductComponentProps = {
  productListFirst: any;
  productListSecond: any;
  comboProductCount: Number;
  handleComboLoadMore?: () => void;
};
const ComboProductSlider = ({
  productListFirst,
  productListSecond,
  handleComboLoadMore,
  comboProductCount,
}: ProductComponentProps) => {
  const [selectedItem, setselectedItem] = useState(0);
  const [manipulatedLists, setManipulatedLists] = useState([]);
  const [productIndex, setProductIndex] = useState({
    firstProductIndex: 0,
    secondProductIndex: 1,
  });
  const combineBothList = () => {
    let combinedProductLists: any = [];
    productListFirst.forEach((item: any, index: any) => {
      combinedProductLists.push(productListFirst[index]);
      combinedProductLists.push(productListSecond[index]);
    });
    setManipulatedLists(combinedProductLists);
  };

  const selectedItemIndex = () => {
    if (selectedItem % 2 === 0) {
      setProductIndex({
        firstProductIndex: selectedItem,
        secondProductIndex: selectedItem + 1,
      });
      return;
    } else {
      setProductIndex({
        firstProductIndex: selectedItem,
        secondProductIndex: selectedItem - 1,
      });
      return;
    }
  };

  useEffect(() => {
    selectedItemIndex();
  }, [selectedItem]);

  useEffect(() => {
    combineBothList();
  }, []);

  return (
    <Box>
      {manipulatedLists?.length ? <ProductComboCard
        otherSymptomProduct={manipulatedLists[productIndex.firstProductIndex]}
        coldSoreProduct={manipulatedLists[productIndex.secondProductIndex]}
        handleComboLoadMore={handleComboLoadMore}
      />: null}
      {productListFirst.length > 1 ? (
        <div className="carousel-swipper">
          <Swiper
            slidesPerView={3.3}
            spaceBetween={15}
            pagination={{
              dynamicBullets: true,
              clickable: true,
            }}
            breakpoints={{
              640: {
                slidesPerView: 5.2,
                spaceBetween: 15,
              },
              768: {
                slidesPerView: 6.2,
                spaceBetween: 15,
              },
              992: {
                slidesPerView: 8.2,
                spaceBetween: 15,
              },
              1024: {
                slidesPerView: 9.5,
                spaceBetween: 15,
              },
              1200: {
                slidesPerView: 10.1,
                spaceBetween: 15,
              },
            }}
            // modules={[Pagination]} //TODO: TO BE REMOVED
            className="mySwiper"
          >
            {manipulatedLists.map((value: any, index: any) => (
              <SwiperSlide key={index}>
                <Box
                  sx={{
                    width: "100px",
                    height: "100px",
                    padding: "10px",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    bordeRadius: "4px",
                    border: `1px solid ${
                      selectedItem === index ? "#F00" : "#D9D9D9"
                    }`,
                  }}
                  onClick={() => setselectedItem(index)}
                >
                  <Box
                    sx={{ width: "40px", height: "40px", marginBottom: "15px" }}
                  >
                    <Img
                      src={[value.productImageURL, medicinePlaceHolder]}
                      alt="medicine"
                      className="product-img"
                    />
                  </Box>
                  <Box
                    sx={{
                      color: "#002744",
                      fontFamily: "HealthCVS",
                      fontSize: "12px",
                      fontStyle: "normal",
                      fontWeight: 400,
                      lineHeight: "19.296px",
                      letterSpacing: "-0.24px",
                      textDecorationLine: "",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      width: "90px",
                    }}
                  >
                    {value.productSkuShortName}
                  </Box>
                </Box>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      ) : null}
    </Box>
  );
};

export default ComboProductSlider;
