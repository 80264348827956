export const ROUTES = {
  ROOT: "/",
  EAR: "/ear",
  CCF: "/ccf",
  QUESTIONS: "/ccf/question:id",
  CHILDCARE: "/ccf/childcare",
  DISCLAIMER: "/ccf/disclaimer",
  PREFERENCES: "/ccf/preferences",
  COMBOPREFERENCESNAVIGATION: "/ccf/fromcombo",
  PRODUCTDETAILS: "/ccf/productdetails",
  FIRSTAID: "/firstaid",
  VITAMINS: "/vitamins",
  VITPREFERENCE:"/vitamins/preference",
  FEVERDISCLAIMER: "/ccf/fever-disclaimer",
  REUSABLEDISCLAIMER: "/product-disclaimer",
  EEQUESTIONS: "/eec/ee_questions",
  EEOUTCOME: "/eec/outcome",
  REQUESTFAILED: "/request-failed",
  NOTFOUND: "*",
};
