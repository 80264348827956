import cvslogo from "../../../assets/cvsLogoNew.svg";
import BackHeader from "../BackHeader";
import { Box } from "@mui/material";

type HeaderProps = {
  handleBack?: () => void;
  handleHome?: () => void;
  backArrow:boolean;
};

export default function ReusableHeader(props: HeaderProps) {
  return (
    <>
      <div className="ccf-header">
        <div className="ccf-header-logo-container">
          <img
            src={cvslogo}
            alt="company-logo"
            className="cvs-logo"
            onClick={props.handleHome}
          />
          {props.handleBack && <Box mt="15px"><BackHeader handleClick={props.handleBack}/></Box>}
        </div>
      </div>
    </>
  );
}
