import { Box, Grid, Rating } from "@mui/material";
import { Img } from "react-image";
import medicinePlaceHolder from "../../../assets/products.png";
import { Product } from "../../../ccf/questions/Types";
import mixpanel from "mixpanel-browser";

type ProductProps = {
  product: Product;
  showHighlighted?: boolean;
  numOfLines?: number;
};
const SingleProduct = ({
  product,
  showHighlighted,
  numOfLines,
}: ProductProps) => {
  let strength;
  if (product.productStrength === "MAXIMUM STRENGTH/EXTRA STRENGTH/SEVERE") {
    strength = "Maximum strength";
  }
  if (product.productStrength === "REGULAR STRENGTH") {
    strength = "Regular Strength";
  }
  const handleLearnMore = () => {
    mixpanel.track("add-to-cart-tapped", {
      type: "ccf",
      productSku: product.productSku,
    });
    if (product.productPageUrl && product.productPageUrl !== "") {
      window.open(`${product.productPageUrl}`, "_blank");
    } else {
      alert("No product URL found");
    }
  };
  return (
    <Box
      sx={{
        borderRadius: "4px",
        border: `1px solid  ${showHighlighted ? "#F00" : "#839DC2"}`,
        padding: "13px",
      }}
    >
      {strength || product.productFormShortName ? (
        <Box className="product_label_container">
          {strength ? <Box className="product_label">{strength}</Box> : ""}
          {product.productFormShortName ? (
            <Box className="product_label" sx={{ marginLeft: "10px" }}>
              {product.productFormShortName}
            </Box>
          ) : (
            ""
          )}
        </Box>
      ) : (
        ""
      )}
      <Grid container>
        {/* Image component */}
        <Grid item xs={5} sx={{ marginTop: "auto", marginBottom: "auto" }}>
          <Box
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Img
              src={[product.productImageURL, medicinePlaceHolder]}
              alt="medicine"
              className="product-img"
            />
          </Box>
        </Grid>
        {/* Image component */}

        <Grid item xs={7} paddingLeft="15px">
          <Box
            sx={{
              color: "#002744",
              fontFamily: "HealthCVS",
              fontSize: "16px",
              fontStyle: "normal",
              fontWeight: 500,
              lineHeight: "24px",
              overflow: "hidden",
              textOverflow: "ellipsis",
              display: "-webkit-box",
              WebkitLineClamp: numOfLines || "2",
              WebkitBoxOrient: "vertical",
            }}
          >
            {product.productSkuShortName}
          </Box>
          <Box
            className="ccf-info-text text-grey-dark2"
            sx={{ marginY: "5px" }}
          >
            {product.productBrand}
          </Box>
          <Box
            sx={{
              color: "#262626",
              fontFamily: "HealthCVS",
              fontSize: "12px",
              fontStyle: "normal",
              fontWeight: "400",
              lineHeight: "19px",
              letterSpacing: "-0.24px",
              marginY: "5px",
            }}
          >
            {`Item # ${product.productSku}`}
          </Box>

          {/* Rating component */}
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Rating
              name="read-only"
              value={product?.productRating ?? null}
              className="rating rating-new"
              readOnly
              sx={{ fontSize: "12px" }}
            />
            <span className="itemsReview">
              {product?.productRating?.toFixed(1)} (
              {product?.productReview ?? "No"} reviews)
            </span>
          </Box>
          {/* Rating component */}

          {/* Learm More  */}
          <Box
            sx={{
              color: "#F00",
              fontFamily: "HealthCVS",
              fontSize: "16px",
              fontStyle: "normal",
              fontWeight: 500,
              lineHeight: "24px",
              marginTop: "5px",
            }}
            onClick={() => handleLearnMore()}
          >
            Learn More
          </Box>
          {/* Learm More  */}
        </Grid>
      </Grid>
    </Box>
  );
};

export default SingleProduct;
