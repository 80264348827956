import React from "react";
import "../../App.css";
import QuestionCard from "../questions/QuestionCard";
import * as questionTypes from "../questions/Types";
import CVSFooter from "../../common/component/Footer/CVSFooter";
import AppHeader from "../../common/component/Header/header";
import * as questionActions from "../../app/store/action";
import { bindActionCreators, Dispatch } from "redux";
import { createStructuredSelector } from "reselect";
import { connect } from "react-redux";
import { GlobalState } from "../../app/store/global-state";
import {
  getAllRecommendedProducts,
  getCurrentQuestion,
  selectedAnswers,
  selectGetQuestions,
  selectGetTotalOfQuestions,
} from "../../app/store/selectors";
import { fetchQuestions } from "../../API/api";
import "../home/home.css";

interface StateProps {
  currentQuestion: questionTypes.Question;
  // nextQuestionId: questionTypes.QuestionState,
  // prevQuestionId: questionTypes.QuestionState,
  questions: questionTypes.Question[];
  totalOfQuestions: number;
  allProducts: questionTypes.ProductState;
  answerState: questionTypes.answerState;
}
interface DispatchProps {
  loadInitialState(payload: questionTypes.LoadInitialQuestionsPayload): void;
  loadInitialProductState(): void;
  loadQuestion(payload: questionTypes.LoadQuestionPayload): void;
  saveAnswer(payload: questionTypes.SaverAnswerPayload): void;
  saveAllProducts(payload: questionTypes.LoadRcommendedProductPayload): void;
}

type Props = StateProps & DispatchProps;

class Questionnaire extends React.Component<Props> {
  mounted = false;
  fetch = async () => {
    let newQuestions = await fetchQuestions();
    this.setState({ questions: newQuestions });
    this.props.loadInitialState({
      questions: newQuestions,
      currentQuestionID: 0,
      answerState: {},
    });
  };

  componentDidMount() {
    this.mounted = true;
    if (this.mounted) {
      this.props.loadInitialState({
        questions: [],
        currentQuestionID: 0,
        answerState: {},
      });
      this.props.loadInitialProductState();
      this.fetch();
    }
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  scrollToTop = () => {
    document.querySelector("#Top")?.scrollIntoView({ behavior: "smooth" });
  };
  render() {
    const { currentQuestion, questions } = this.props;
    return questions.length > 0 ? (
      <>
        <div id="Top"></div>{" "}
        {/* pupose of this component is to scroll user to top of the screen */}
        <AppHeader
          loadQuestion={this.props.loadQuestion}
          previousIdQuestion={currentQuestion.questionId}
          isBackAllow={false}
        />
        <QuestionCard
          key={currentQuestion.questionId}
          question={currentQuestion}
          questions={questions}
          loadQuestion={this.props.loadQuestion}
          saveAnswer={this.props.saveAnswer}
          allProducts={this.props.allProducts}
          saveAllProducts={this.props.saveAllProducts}
          answerState={this.props.answerState}
          scrollToTop={this.scrollToTop}
        />
        {currentQuestion.isMultiselectAllow && (
          <CVSFooter
            loadQuestion={this.props.loadQuestion}
            nextIdQuestion={this.props.currentQuestion.questionId}
            allProducts={this.props.allProducts}
            saveAllProducts={this.props.saveAllProducts}
            activeButton={!!this.props.answerState.symptoms?.length}
            scrollToTop={this.scrollToTop}
          />
        )}
      </>
    ) : null;
  }
}

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(questionActions, dispatch);

const mapStateToProps = createStructuredSelector<GlobalState, StateProps>({
  currentQuestion: getCurrentQuestion,
  // nextQuestionId: getnextQuestionID,
  allProducts: getAllRecommendedProducts,
  questions: selectGetQuestions,
  totalOfQuestions: selectGetTotalOfQuestions,
  answerState: selectedAnswers,
});

export default connect(mapStateToProps, mapDispatchToProps)(Questionnaire);
