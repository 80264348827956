import "./EElanding.css";
import { useDispatch, useSelector } from "react-redux";
import { GlobalState } from "../app/store/global-state";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../common/Routing/Routing";
import {
  childCareBtn,
  childCareDescription,
  childCareTitle,
  disclaimerBtn,
  disclaimerDescription,
} from "./EE_Consts";
import CircularProgress from "@mui/material/CircularProgress";
import store from "../app/store";
import {
  saveUserChoice,
  setPreviousQuestion,
  setPrevReq,
  setQuestion,
  setReqAge,
  setReqOptionId,
  setReqQuestion,
  setReqQuestionId,
  setTrackUserChoice,
  setUpdatePreviousQuestion,
  setUpdatePrevReq,
  setUpdateUserchoice,
  setUserChoice,
} from "./EE_careSlice";
import { useEffect, useState } from "react";
import mixpanel from "mixpanel-browser";
import { Box, Grid } from "@mui/material";
import tablet from "../assets/product.svg";
import customerAge from "../assets/customerAge.svg";
import eyeAllergy from "../assets/eyecare.svg";
import contactLens from "../assets/lens.svg";
import disposeble from "../assets/calender.svg";
import solution from "../assets/solution.svg";
import hoursOfWear from "../assets/time.svg";
import ReusableHeader from "../common/component/Header/reusableheader";
import { getNextQuestion } from "../API/api";
import EEItemCard from "./EEItemCard";
import { manipulatedQuestionArray } from "../app/store/selectors";
import { isEmpty } from "../ccf/Utils/helper";
import FooterLogo from "../common/component/BottomLogo";
export default function EEQuestionCard() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [isLoading, setLoading] = useState(false);

  // questions data
  const ee_state = useSelector((state: GlobalState) => state.eeCare);
  const question = useSelector((state: GlobalState) =>
    manipulatedQuestionArray(state.eeCare.question || {})
  );
  useEffect(() => {
    mixpanel.time_event("question");
  }, []);

  // Back navigation
  function handleBack() {
    dispatch(setUpdateUserchoice());
    if (ee_state.previousQuestion.length === 0) {
      navigate(ROUTES.ROOT);
      return;
    }
    let prevque = ee_state.previousQuestion.length;
    let reqlen = ee_state.previousReq.length;
    dispatch(setUpdatePreviousQuestion());
    let getcurrentUsrChoice = store.getState().eeCare.trackUserChoice;
    let usrChoice = getcurrentUsrChoice.length;
    dispatch(setUpdatePrevReq());
    dispatch(setQuestion(ee_state.previousQuestion[prevque - 1]));
    dispatch(setReqQuestion(ee_state.previousReq[reqlen - 1]));
    //  set userchoice to previous choice
    if (usrChoice > 0) {
      dispatch(setUserChoice(ee_state.trackUserChoice[usrChoice - 1]));
    }
  }
  // Logo click
  function handleLogoClick() {
    mixpanel.track("eec_header_clicked", { type: "eec" });
    navigate(ROUTES.ROOT);
  }

  // did n't find any use case of this code
  // filter options
  // if (question.questionId === 1 && ee_state.previousId === "4") {
  //   options = options.slice(0, 4);
  // }
  // if (question.questionId === 1 && ee_state.previousId === "3") {
  //   options = options.slice(0, 2);
  // }
  // if (question.questionId === 1 && ee_state.previousId === "2") {
  //   options = options.slice(0, 3);
  // }
  // if (question.questionId === 2 && ee_state.previousId === "7") {
  //   options = options.filter((item: any) => item.key !== "13");
  // }
  // if (question.questionId === 13 && ee_state.previousId === "6") {
  //   options = options.slice(1);
  // }

  function handleClick(item: any) {
    dispatch(setPreviousQuestion(question));
    mixpanel.track("eec-question-loaded", {
      type: "eec",
      eec_question: question.que[0].question,
    });
    mixpanel.track("eec-question", {
      type: "eec",
      eec_question: question.que[0].question,
      eec_option: item.option,
    });
    // child care route
    if (parseInt(item.id) === 8 || parseInt(item.id) === 9) {
      navigate(ROUTES.CHILDCARE, {
        state: {
          title: `${childCareTitle}`,
          description: `${childCareDescription}`,
          buttonText: `${childCareBtn}`,
          route: `${ROUTES.ROOT}`,
          mixPanelEvent: "eec-childcare",
        },
      });
      return;
    }
    if (question && question.que && question.que[0].id === 2) {
      dispatch(saveUserChoice(parseInt(item.id)));
      dispatch(setReqQuestionId(question.que[0].id));
      dispatch(setReqAge(parseInt(item.id)));
      const reqQue = store.getState().eeCare.reqQuestion;
      dispatch(setPrevReq(reqQue));
      dispatch(setTrackUserChoice());
      let mixPanelAnswerCountEEC = store.getState().eeCare.trackUserChoice;
      let mixPanellevelCountEEC = store.getState().eeCare.previousReq;
      console.log("eec track", mixPanelAnswerCountEEC, mixPanellevelCountEEC);
      // mix panel
      mixpanel.track("question", {
        type: "eec",
        level: mixPanellevelCountEEC.length,
        answer: mixPanelAnswerCountEEC.length,
        text: item.option,
      });
      setLoading(true);
      // get questions as per answer selected for Age
      getNextQuestion(reqQue)
        .then((res) => {
          dispatch(setQuestion(res));
          mixpanel.track("eec-ee_questions");
          navigate(ROUTES.EEQUESTIONS, {
            state: {
              description: `${disclaimerDescription}`,
              buttonText: `${disclaimerBtn}`,
              route: `${ROUTES.EEQUESTIONS}`,
              cancel: `${ROUTES.ROOT}`,
            },
          });
        })
        .catch((error) => {
          navigate(ROUTES.REQUESTFAILED);
        })
        .finally(() => setLoading(false));
    } else {
      dispatch(saveUserChoice(parseInt(item.id)));
      dispatch(setReqQuestionId(question.que[0].id));
      dispatch(setReqOptionId(parseInt(item.id)));
      const reqQue = store.getState().eeCare.reqQuestion;
      dispatch(setPrevReq(reqQue));
      dispatch(setTrackUserChoice());
      let mixPanelAnswerCountEEC = store.getState().eeCare.trackUserChoice;
      let mixPanellevelCountEEC = store.getState().eeCare.previousReq;
      console.log("eec track", mixPanelAnswerCountEEC, mixPanellevelCountEEC);
      // mix panel
      mixpanel.track("question", {
        type: "eec",
        level: mixPanellevelCountEEC.length,
        answer: mixPanelAnswerCountEEC.length,
        text: item.option,
      });
      setLoading(true);
      getNextQuestion(reqQue)
        .then((res) => {
          let data = res;
          if (!isEmpty(data) && data.isFinal) {
            navigate(ROUTES.EEOUTCOME)
          } else if (!isEmpty(data)) {
            dispatch(setQuestion(data));
            setLoading(false);
          }
        })
        .catch((error) => {
          setLoading(false);
          navigate(ROUTES.REQUESTFAILED);
          
        });
    }
  }

  let gridImage = tablet;
  if (!isEmpty(question)) {
    if (question.option[0].questionId === 2) {
      gridImage = customerAge;
    }
    if (question.option[0].questionId === 4) {
      gridImage = eyeAllergy;
    }
    if (question.option[0].questionId === 7) {
      gridImage = contactLens;
    }
    if (question.option[0].questionId === 9) {
      gridImage = disposeble;
    }
    if (question.option[0].questionId === 10) {
      gridImage = solution;
    }
    if (question.option[0].questionId === 8) {
      gridImage = hoursOfWear;
    }
  }

  return (
    <>
      <ReusableHeader
        handleBack={handleBack}
        handleHome={handleLogoClick}
        backArrow={true}
      />
      {/* <AppHeader isBackAllow={false} /> */}
      {isLoading || isEmpty(question)  ? (
        <div className="loading">
          <CircularProgress sx={{ color: "#CC0000" }} />
        </div>
      ) : (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            flexGrow: 1,
            justifyContent: "space-between",
          }}
        >
          <div
            style={{
              backgroundColor: "#ffffff",
              padding: "0 24px",
            }}
          >
            <div className="ee_questioncard_container">
              <p className="ee_question_title">{question.que[0].question} </p>
              <p className="ee_question_subtitle">Please select one</p>
            </div>
            <Grid container sx={{marginTop:"10px"}}>
              <Grid item xs={12} sm={6}>
                <EEItemCard
                  question={question}
                  handleClick={(option: any) => handleClick(option)}
                  ee_state={ee_state}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Box
                  sx={{
                    width: "100%",
                    height: "100%",
                    justifyContent: "center",
                    alignItems: "center",
                    display: { xs: "none", sm: "flex" },
                  }}
                >
                  <Box sx={{ width: "50%" }}>
                    <img
                      src={gridImage}
                      alt="img"
                      style={{
                        width: "100%",
                        height: "150px",
                        objectFit: "contain",
                      }}
                    />
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </div>
          <FooterLogo />
        </Box>
      )}
    </>
  );
}
