import { action } from "typesafe-actions";
import * as questionTypes from "../../ccf/questions/Types";

export const loadInitialState = (
  payload: questionTypes.LoadInitialQuestionsPayload
) => action(questionTypes.QuestionActionsType.LOAD_INITIAL_STATE, payload);

export const loadInitialProductState = (
) => action(questionTypes.QuestionActionsType.PRODUCT_INITIAL_STATE);

export const loadQuestion = (payload: questionTypes.LoadQuestionPayload) =>
  action(questionTypes.QuestionActionsType.NEXT_QUESTION, payload);

export const saveAnswer = (payload: questionTypes.SaverAnswerPayload) =>
  action(questionTypes.QuestionActionsType.SAVE_ANSWER, payload);

export const saveAllProducts = (
  payload: questionTypes.LoadRcommendedProductPayload
) => action(questionTypes.QuestionActionsType.SAVE_PRODUCTS, payload);
