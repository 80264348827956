import { Box } from "@mui/material";
import  { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
// import ProductCard from "../ProductCard/ProductCard";
import SingleProduct from "../ProductCard/SingleProduct";

import medicinePlaceHolder from "../../../assets/products.png";
import { Img } from "react-image";
type ProductComponentProps = {
  products: any;
  showHighlighted? : any
};
const SingleProductSlider = ({ products }: ProductComponentProps) => {
  const [selectedItem, setselectedItem] = useState(0);
  return (
    <Box>
      {products.length ? <SingleProduct product={products[selectedItem]} showHighlighted={!!(products.length > 1)} numOfLines={2}/> : ""}
      {products.length > 1 ? <div className="carousel-swipper">
        <Swiper
          slidesPerView={3.3}
          spaceBetween={5}
          pagination={{
            dynamicBullets: true,
            clickable: true,
          }}
          breakpoints={{
            480: {
              slidesPerView: 4.2,
              spaceBetween: 5,
            },
            640: {
              slidesPerView: 4.2,
              spaceBetween: 5,
            },
            768: {
              slidesPerView: 6.2,
              spaceBetween: 5,
            },
            992: {
              slidesPerView: 8.2,
              spaceBetween: 5,
            },
            1024: {
              slidesPerView: 9.5,
              spaceBetween: 5,
            },
            1200: {
              slidesPerView: 10.1,
              spaceBetween: 5,
            },
          }}
          // modules={[Pagination]} //TODO: TO BE REMOVED
          className="mySwiper"
        >
          {products && products.map((value: any, index: any) => (
            <SwiperSlide key={index}>
              <Box
              key={index}
                sx={{
                  width: "100px",
                  height: "100px",
                  padding: "10px",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  bordeRadius: "4px",
                  border: `1px solid ${selectedItem === index ? "#F00" : "#D9D9D9"}`,
                }}
                onClick={()=>setselectedItem(index)}
              >
                <Box sx={{ width: "40px", height: "40px",marginBottom:'15px'}}>
                  <Img
                    src={[value.productImageURL, medicinePlaceHolder]}
                    alt="medicine"
                    className="product-img"
                  />
                </Box>
                <Box
                  sx={{
                    color: "#002744",
                    fontFamily: "HealthCVS",
                    fontSize: "12px",
                    fontStyle: "normal",
                    fontWeight: 400,
                    lineHeight: "19.296px",
                    letterSpacing: "-0.24px",
                    textDecorationLine: "",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    width:'90px'
                    
                  }}
                >
                  {value.productSkuShortName}
                </Box>
              </Box>


              {/* Need to delete component as well */}
              {/* <ProductCard key={index} product={value} /> */}
            </SwiperSlide>
          ))}
        </Swiper>
      </div> : null}
    </Box>
  );
};

export default SingleProductSlider;
