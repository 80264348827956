import Box from "@mui/material/Box";
import "./productpreference.css";
import "./productpreference.css";
import AppHeader from "../Header/header";
import { FunctionComponent, useEffect, useMemo, useRef } from "react";
import * as questionTypes from "../../../ccf/questions/Types";
import { connect, useDispatch, useSelector } from "react-redux";
import * as questionActions from "../../../app/store/action";
import { bindActionCreators, Dispatch } from "redux";
import { createStructuredSelector } from "reselect";
import { GlobalState } from "../../../app/store/global-state";
import {
  getAllRecommendedProducts,
  selectedAnswers,
} from "../../../app/store/selectors";
import React from "react";
import { getAddons, getProductRecommendations } from "../../../API/api";
import AddonCard from "../Addoncard/AddonCard";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { styled } from "@mui/material/styles";
import PrefferedSelection from "../Preferredselection/PreferedSelection";
import { useVariable } from "@devcycle/devcycle-react-sdk";
import mixpanel from "mixpanel-browser";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../Routing/Routing";
import CircularProgress from "@mui/material/CircularProgress";
import BackdropUnstyled from "@mui/base/BackdropUnstyled";
import { Modal, Stack } from "@mui/material";

import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";

//Import Swiper React components
import { Swiper } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

// import required modules
import { Pagination } from "swiper";
import { Product } from "../../../ccf/questions/Types";
import FooterLogo from "../BottomLogo";
import FeedbackPopup from "./FeedbackPopup";
import SingleProductSlider from "./SingleProductSlider";
import ComboProductSlider from "./ComboProductSlider";
import { useIdleTimer } from "react-idle-timer";
import { setFeedbackSubmited } from "../../../eecare/EE_careSlice";
interface StateProps {
  allProducts: questionTypes.ProductState;
  answerState: questionTypes.answerState;
}
interface DispatchProps {
  loadInitialProductState(): void;
  saveAllProducts(payload: questionTypes.LoadRcommendedProductPayload): any;
}

type Props = StateProps & DispatchProps;

enum UIScenario {
  COMBO = 0,
  ONLY_COLDSORE = 1,
  ONLY_OTHERSYMPTOMS = 2,
  BOTH_AFTER_COMBO = 3,
  NONE = 4,
}
var currenScenario = UIScenario.NONE;
let givenPreferences: string[] = [];
let symptom1Products: questionTypes.Product[] = [];
let symptom2Products: questionTypes.Product[] = [];

const ProductPreference: FunctionComponent<Props> = ({
  allProducts,
  answerState,
  loadInitialProductState,
  saveAllProducts,
}) => {
  const [isLoading, setIsLoading] = React.useState(false);
  const [showProductForm, setShowProductForms] = React.useState(false);
  const [comboProductCount, setComboProductCount] = React.useState(0);
  const [openFeedbackPopup, setOpenFeedbackPopup] = React.useState("");
  const experience = useSelector(
    (state: GlobalState) => state?.eeCare?.userChoice?.user_experiment
  );
  const feedbackSubmited = useSelector(
    (state: GlobalState) => state?.eeCare?.feedbackSubmited
  );

  const [allFinalAddOns, setAddOnsState] =
    React.useState<questionTypes.Product[]>();
  const dispatch = useDispatch();
  const onsetFeatureKey = "add-ons";
  const onsetFeatureVariable = useVariable(onsetFeatureKey, false);
  const immunityFeatureKey = "immunity";
  const immunityFeatureVariable = useVariable(immunityFeatureKey, false);
  let navigate = useNavigate();
  let isSubscribed = useRef(true);
  let isSubscribed1 = useRef(true);

  function trackMixpanelSkus(allProducts: any) {
    //  Mix panel combo TBD
    let skeuWithPosition = [];
    let resultSkus: any[] = [];
    if (
      allProducts &&
      allProducts.otherSymptomsProduct.length !== 0 &&
      allProducts.coldSoreProduct.length !== 0
    ) {
      let skeuWithPositionOther = [];
      let skeuWithPositionColdSore = [];
      let resultSkuOther = [];
      let resultSKuColdSore = [];
      if (
        allProducts.otherSymptomsProduct !== undefined &&
        allProducts.otherSymptomsProduct.length !== 0
      ) {
        for (
          let pos = 0;
          pos < allProducts.otherSymptomsProduct.length;
          pos++
        ) {
          let obj = { position: 0, sku: 0, skuBrand: "" };
          mixpanel.track("ccf-product-with-sku-position", {
            type: "ccf",
            ccf_product: allProducts.otherSymptomsProduct[pos].productSku,
            cc_sku_postion: pos + 1,
            ccf_product_brand:
              allProducts.otherSymptomsProduct[pos].productBrand,
          });
          obj.position = pos + 1;
          obj.skuBrand = allProducts.otherSymptomsProduct[pos].productBrand;
          obj.sku = allProducts.otherSymptomsProduct[pos].productSku;
          skeuWithPositionOther.push(obj);
          resultSkuOther.push(allProducts.otherSymptomsProduct[pos].productSku);
        }
        mixpanel.track("ccf-recommended-products", {
          type: "ccf",
          Recommended: "COMBO_OTHERSYMPTOMS",
          ResultSKUs: resultSkuOther,
          ReturnedProducts: resultSkuOther.length ?? 0,
          skeuWithPosition: skeuWithPositionOther,
        });
      }
      if (
        allProducts.coldSoreProduct !== undefined &&
        allProducts.coldSoreProduct.length !== 0
      ) {
        for (let pos = 0; pos < allProducts.coldSoreProduct.length; pos++) {
          let obj = { position: 0, sku: 0, skuBrand: "" };
          mixpanel.track("ccf-product-with-sku-position", {
            type: "ccf",
            ccf_product_sku: allProducts.coldSoreProduct[pos].productSku,
            cc_sku_postion: pos + 1,
            ccf_product_brand: allProducts.coldSoreProduct[pos].productBrand,
          });
          obj.position = pos + 1;
          obj.skuBrand = allProducts.coldSoreProduct[pos].productBrand;
          obj.sku = allProducts.coldSoreProduct[pos].productSku;
          skeuWithPositionColdSore.push(obj);
          resultSKuColdSore.push(allProducts.coldSoreProduct[pos].productSku);
        }
        mixpanel.track("ccf-recommended-products", {
          type: "ccf",
          Recommended: "COMBO_COLDSORE",
          ResultSKUs: resultSKuColdSore,
          ReturnedProducts: resultSKuColdSore?.length ?? 0,
          skeuWithPosition: skeuWithPositionColdSore,
        });
      }
      return;
    }
    if (
      allProducts.otherSymptomsProduct !== undefined &&
      allProducts.otherSymptomsProduct.length !== 0
    ) {
      for (let pos = 0; pos < allProducts.otherSymptomsProduct.length; pos++) {
        let obj = { position: 0, sku: 0, skuBrand: "" };
        mixpanel.track("ccf-product-with-sku-position", {
          type: "ccf",
          ccf_product: allProducts.otherSymptomsProduct[pos].productSku,
          cc_sku_postion: pos + 1,
          ccf_product_brand: allProducts.otherSymptomsProduct[pos].productBrand,
        });
        resultSkus.push(allProducts.otherSymptomsProduct[pos].productSku);
        obj.position = pos + 1;
        obj.skuBrand = allProducts.otherSymptomsProduct[pos].productBrand;
        obj.sku = allProducts.otherSymptomsProduct[pos].productSku;
        skeuWithPosition.push(obj);
      }
      mixpanel.track("ccf-recommended-products", {
        type: "ccf",
        Recommended: "ONLY_OTHERSYMPTOMS",
        ResultSKUs: resultSkus,
        ReturnedProducts: resultSkus.length ?? 0,
        skeuWithPosition: skeuWithPosition,
      });
      return;
    }
    if (
      allProducts.coldSoreProduct !== undefined &&
      allProducts.coldSoreProduct.length !== 0
    ) {
      for (let pos = 0; pos < allProducts.coldSoreProduct.length; pos++) {
        let obj = { position: 0, sku: 0, skuBrand: "" };
        mixpanel.track("ccf-product-with-sku-position", {
          type: "ccf",
          ccf_product_sku: allProducts.coldSoreProduct[pos].productSku,
          cc_sku_postion: pos + 1,
          ccf_product_brand: allProducts.coldSoreProduct[pos].productBrand,
        });
        resultSkus.push(allProducts.coldSoreProduct[pos].productSku);
        obj.position = pos + 1;
        obj.skuBrand = allProducts.coldSoreProduct[pos].productBrand;
        obj.sku = allProducts.coldSoreProduct[pos].productSku;
        skeuWithPosition.push(obj);
      }
      mixpanel.track("ccf-recommended-products", {
        type: "ccf",
        Recommended: "ONLY_COLDSORE",
        ResultSKUs: resultSkus,
        ReturnedProducts: resultSkus?.length ?? 0,
        skeuWithPosition: skeuWithPosition,
      });

      return;
    }
  }

  const handleComboLearnMore = () => {
    navigate(ROUTES.COMBOPREFERENCESNAVIGATION, { state: true });
  };

  const onIdle = () => {
    !feedbackSubmited &&
      openFeedbackPopup !== "close" &&
      setOpenFeedbackPopup("open");
  };

  useIdleTimer({
    onIdle,
    timeout: 20000,
    throttle: 500,
  });

  const onSubmitFeedback = (feedback: string) => {
    mixpanel.track("user-feedback", {
      type: "ccf",
      ui_experiment: experience,
      feedback: feedback,
    });
    dispatch(setFeedbackSubmited(true));
    setOpenFeedbackPopup("close");
  };
  // Porducts code
  useEffect(() => {
    setIsLoading(true);
    loadInitialProductState();
    const finalRequest = answerState;
    async function fetchProducts() {
      // call API
      let allFinalProduct: any = await getProductRecommendations(finalRequest);
      // call reducer action
      if (
        !showProductForm &&
        finalRequest.hasOwnProperty("productForm") &&
        !(
          allFinalProduct.hasOwnProperty("otherSymptomsProduct") &&
          (allFinalProduct.hasOwnProperty("coldSoreProduct") ||
            allFinalProduct.hasOwnProperty("soreThroatProduct"))
        )
      ) {
        if (allFinalProduct.otherSymptomsProduct !== undefined) {
          allFinalProduct.otherSymptomsProduct =
            allFinalProduct.otherSymptomsProduct.filter((product: Product) => {
              if (finalRequest.productForm?.includes(product.productForm)) {
                return product;
              }
            });
        }
        if (allFinalProduct.coldSoreProduct !== undefined) {
          allFinalProduct.coldSoreProduct =
            allFinalProduct.coldSoreProduct.filter((product: Product) => {
              if (finalRequest.productForm?.includes(product.productForm)) {
                return product;
              }
            });
        }
        if (allFinalProduct.soreThroatProduct !== undefined) {
          allFinalProduct.soreThroatProduct =
            allFinalProduct.soreThroatProduct.filter((product: Product) => {
              if (finalRequest.productForm?.includes(product.productForm)) {
                return product;
              }
            });
        }
      }
      saveAllProducts(await allFinalProduct);
      trackMixpanelSkus(allFinalProduct);
    }
    fetchProducts();
    return () => {
      isSubscribed.current = false;
    };
  }, [answerState, showProductForm]);

  // Add on code
  useEffect(() => {
    setComboProductCount(getMinLengthForCombo);
    let addOnRequest: any = {};
    addOnRequest.symptoms = answerState.symptoms;
    addOnRequest.age = answerState.age;
    addOnRequest.onset = onsetFeatureVariable.value ? "111" : "222";
    addOnRequest.immunity = immunityFeatureVariable.value ? "111" : "222";

    async function fetchAddOns() {
      // call API
      let allFinalAddOns;
      await getAddons(addOnRequest).then((data) => {
        if (data.length > 0) {
          allFinalAddOns = data;
        } else if (data.length === 0) {
          allFinalAddOns = data;
        } else {
          navigate(ROUTES.REQUESTFAILED);
        }
      });
      setAddOnsState(await allFinalAddOns);
      setTimeout(() => {
        setIsLoading(false);
      }, 1000);
    }

    fetchAddOns();
    if (
      (allProducts.otherSymptomsProduct?.length! > 0 &&
        allProducts.coldSoreProduct?.length! > 0) ||
      (allProducts.otherSymptomsProduct?.length! > 0 &&
        allProducts.soreThroatProduct?.length! > 0)
    ) {
      currenScenario = UIScenario.COMBO;
    } else if (
      answerState.symptoms?.includes("Cold Sore") &&
      allProducts.coldSoreProduct?.length! > 0
    ) {
      currenScenario = UIScenario.ONLY_COLDSORE;
    } else if (
      !answerState.symptoms?.includes("Cold Sore") &&
      allProducts.otherSymptomsProduct?.length! > 0
    ) {
      currenScenario = UIScenario.ONLY_OTHERSYMPTOMS;
    } else {
      currenScenario = UIScenario.BOTH_AFTER_COMBO;
    }
    return () => {
      isSubscribed1.current = false;
    };
  }, [allProducts]);

  useEffect(() => {
    if (allFinalAddOns && allFinalAddOns.length !== 0) {
      for (let item of allFinalAddOns) {
        mixpanel.track("add-ons", {
          type: "ccf",
          productSku: item.productSku,
          productBrand: item.productBrand,
        });
      }
    }
  }, [allFinalAddOns?.length]);

  useEffect(() => {
    mixpanel.track("ccf-preferences-Selected", {
      type: "ccf",
      preferences: answerState,
    });
  }, []);
  /*selected  Preferences dialog*/
  const [open, setOpen] = React.useState(false);

  const getMinLengthForCombo = (): number => {
    const otherSymptomsLength = allProducts.otherSymptomsProduct?.length ?? 0;
    const coldSoreLength = allProducts.coldSoreProduct?.length ?? 0;

    return otherSymptomsLength > coldSoreLength
      ? coldSoreLength
      : otherSymptomsLength;
  };

  const handleClickOpen = () => {
    mixpanel.track("ccf-view-selected-preference-tapped", { type: "ccf" });
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleStartOver = () => {
    //Rest state
    dispatch(
      questionActions.loadInitialState({
        questions: [],
        currentQuestionID: 0,
        answerState: {},
      })
    );
    mixpanel.track("ccf_start_over_clicked", { type: "ccf" });
    navigate(ROUTES.QUESTIONS);
  };

  const startOver = (
    <div>
      <button className="btn-primary-prefence" onClick={handleStartOver}>
        Start Over
      </button>
    </div>
  );

  // Prod Pref code
  const onMemo = useMemo(() => {
    givenPreferences = [];
    if (allProducts.isTwoSymptomsPartialMatch) {
      allProducts.otherSymptomsProduct?.map((product, key) => {
        answerState.symptoms &&
        answerState.symptoms?.length > 0 &&
        product.symptom === answerState.symptoms[0]
          ? symptom1Products.push(product)
          : symptom2Products.push(product);
      });
    }

    allProducts.otherSymptomsProduct?.map((product, key) => {
      !givenPreferences.includes(product.productForm) &&
        (givenPreferences = [...givenPreferences, product.productForm]);
    });

    switch (currenScenario) {
      case UIScenario.ONLY_COLDSORE:
        return (
          <div>
            <h3 className="productSlider_heading">{allProducts?.soreThroatProduct?.length ? "Sore Throat" :  "Cold Sore"}</h3>
            <div>
              {allProducts.coldSoreProduct && (
                <div className="carousel-swipper">
                  <Swiper
                    slidesPerView={1}
                    spaceBetween={15}
                    pagination={{
                      dynamicBullets: true,
                      clickable: true,
                    }}
                    breakpoints={{
                      640: {
                        slidesPerView: 1,
                        spaceBetween: 15,
                      },
                      768: {
                        slidesPerView: 2,
                        spaceBetween: 15,
                      },
                      992: {
                        slidesPerView: 3,
                        spaceBetween: 15,
                      },
                      1024: {
                        slidesPerView: 3,
                        spaceBetween: 15,
                      },
                      1200: {
                        slidesPerView: 4,
                        spaceBetween: 15,
                      },
                    }}
                    // modules={[Pagination]} //TODO: TO BE REMOVED
                    className="mySwiper"
                  >
                    <SingleProductSlider
                      products={allProducts.coldSoreProduct}
                    />
                  </Swiper>
                </div>
              )}
            </div>
          </div>
        );
      case UIScenario.ONLY_OTHERSYMPTOMS:
        return (
          <>
            {allProducts && allProducts.isTwoSymptomsPartialMatch && (
              <>
                <div>
                  <label>symptom 1</label>
                  {symptom1Products.length > 0 && (
                    <div className="carousel-swipper">
                      <Swiper
                        slidesPerView={1}
                        spaceBetween={15}
                        pagination={{
                          dynamicBullets: true,
                          clickable: true,
                        }}
                        breakpoints={{
                          640: {
                            slidesPerView: 2.1,
                            spaceBetween: 15,
                          },
                          768: {
                            slidesPerView: 2.2,
                            spaceBetween: 15,
                          },
                          992: {
                            slidesPerView: 3.2,
                            spaceBetween: 15,
                          },
                          1024: {
                            slidesPerView: 3.5,
                            spaceBetween: 15,
                          },
                          1200: {
                            slidesPerView: 4.1,
                            spaceBetween: 15,
                          },
                        }}
                        // modules={[Pagination]} //TODO: TO BE REMOVED
                        className="mySwiper"
                      >
                        <SingleProductSlider products={symptom1Products} />
                      </Swiper>
                    </div>
                  )}
                </div>

                <div>
                  <label>symptom 2</label>
                  {symptom1Products.length > 0 && (
                    <div className="carousel-swipper">
                      <Swiper
                        slidesPerView={1}
                        spaceBetween={15}
                        pagination={{
                          dynamicBullets: true,
                          clickable: true,
                        }}
                        breakpoints={{
                          640: {
                            slidesPerView: 2.1,
                            spaceBetween: 15,
                          },
                          768: {
                            slidesPerView: 2.2,
                            spaceBetween: 15,
                          },
                          992: {
                            slidesPerView: 3.2,
                            spaceBetween: 15,
                          },
                          1024: {
                            slidesPerView: 3.5,
                            spaceBetween: 15,
                          },
                          1200: {
                            slidesPerView: 4.1,
                            spaceBetween: 15,
                          },
                        }}
                        // modules={[Pagination]} //TODO: TO BE REMOVED
                        className="mySwiper"
                      >
                        <SingleProductSlider products={symptom1Products} />
                      </Swiper>
                    </div>
                  )}
                </div>
              </>
            )}
            {allProducts && !allProducts.isTwoSymptomsPartialMatch && (
              <div>
                {allProducts.otherSymptomsProduct && (
                  <div className="carousel-swipper">
                    <Swiper
                      slidesPerView={1}
                      spaceBetween={15}
                      pagination={{
                        dynamicBullets: true,
                        clickable: true,
                      }}
                      breakpoints={{
                        640: {
                          slidesPerView: 1,
                          spaceBetween: 15,
                        },
                        768: {
                          slidesPerView: 2,
                          spaceBetween: 15,
                        },
                        992: {
                          slidesPerView: 3,
                          spaceBetween: 15,
                        },
                        1024: {
                          slidesPerView: 3,
                          spaceBetween: 15,
                        },
                        1200: {
                          slidesPerView: 4,
                          spaceBetween: 15,
                        },
                      }}
                      // modules={[Pagination]} //TODO: TO BE REMOVED
                      className="mySwiper"
                    >
                      <SingleProductSlider
                        products={allProducts.otherSymptomsProduct}
                      />
                    </Swiper>
                  </div>
                )}
              </div>
            )}
          </>
        );
      case UIScenario.BOTH_AFTER_COMBO:
        return (
          <>
            {allProducts.otherSymptomsProduct && (
              <div className="carousel-swipper">
                <Swiper
                  slidesPerView={1}
                  spaceBetween={15}
                  pagination={{
                    dynamicBullets: true,
                    clickable: true,
                  }}
                  breakpoints={{
                    640: {
                      slidesPerView: 2.1,
                      spaceBetween: 15,
                    },
                    768: {
                      slidesPerView: 2.2,
                      spaceBetween: 15,
                    },
                    992: {
                      slidesPerView: 3.2,
                      spaceBetween: 15,
                    },
                    1024: {
                      slidesPerView: 3.5,
                      spaceBetween: 15,
                    },
                    1200: {
                      slidesPerView: 4.1,
                      spaceBetween: 15,
                    },
                  }}
                  // modules={[Pagination]} //TODO: TO BE REMOVED
                  className="mySwiper"
                >
                  <SingleProductSlider
                    products={allProducts.otherSymptomsProduct}
                  />
                </Swiper>
              </div>
            )}
            <div style={{ marginTop: "20px" }}>
              <h3>{allProducts?.soreThroatProduct?.length ? "Sore Throat" :  "Cold Sore"}</h3>
              {allProducts.coldSoreProduct && (
                <div className="carousel-swipper">
                  <Swiper
                    slidesPerView={1}
                    spaceBetween={15}
                    pagination={{
                      dynamicBullets: true,
                      clickable: true,
                    }}
                    breakpoints={{
                      640: {
                        slidesPerView: 2.1,
                        spaceBetween: 15,
                      },
                      768: {
                        slidesPerView: 2.2,
                        spaceBetween: 15,
                      },
                      992: {
                        slidesPerView: 3.2,
                        spaceBetween: 15,
                      },
                      1024: {
                        slidesPerView: 3.5,
                        spaceBetween: 15,
                      },
                      1200: {
                        slidesPerView: 4.1,
                        spaceBetween: 15,
                      },
                    }}
                    // modules={[Pagination]} //TODO: TO BE REMOVED
                    className="mySwiper"
                  >
                    <SingleProductSlider
                      products={allProducts.coldSoreProduct}
                    />
                  </Swiper>
                </div>
              )}
            </div>
          </>
        );
      case UIScenario.COMBO:
        return (
          <>
            <div className="carousel-swipper">
              <Swiper
                slidesPerView={1}
                spaceBetween={15}
                pagination={{
                  dynamicBullets: true,
                  clickable: true,
                }}
                breakpoints={{
                  640: {
                    slidesPerView: 2.1,
                    spaceBetween: 15,
                  },
                  768: {
                    slidesPerView: 2.2,
                    spaceBetween: 15,
                  },
                  992: {
                    slidesPerView: 3.2,
                    spaceBetween: 15,
                  },
                  1024: {
                    slidesPerView: 3.5,
                    spaceBetween: 15,
                  },
                  1200: {
                    slidesPerView: 4.1,
                    spaceBetween: 15,
                  },
                }}
                // modules={[Pagination]} //TODO: TO BE REMOVED
                className="mySwiper"
              ></Swiper>
              <ComboProductSlider
                productListFirst={allProducts.otherSymptomsProduct}
                productListSecond={
                  allProducts && allProducts.coldSoreProduct
                    ? allProducts.coldSoreProduct
                    : allProducts.soreThroatProduct
                }
                handleComboLoadMore={handleComboLearnMore}
                comboProductCount={comboProductCount}
              />
            </div>
          </>
        );
      case UIScenario.NONE:
        break;
    }
  }, [currenScenario, allProducts]);

  return (
    <>
      <AppHeader
        loadQuestion={null}
        previousIdQuestion={null}
        isBackAllow={false}
      />
      {isLoading && (
        <BackdropUnstyled
          style={{
            display: "flex",
            height: "100vh",
            justifyContent: "center",
            background: "#ffffff",
            position: "absolute",
            zIndex: 500,
            width: "100%",
          }}
        >
          <CircularProgress
            sx={{ color: "#CC0000", alignSelf: "center", zIndex: "1" }}
          />
        </BackdropUnstyled>
      )}
      <Box
        sx={{
          padding: "0 24px 15px",
          background: "#ffffff",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <Stack>
          {/* card container */}
          <Box
            sx={{
              display: "flex",
              flexWrap: "nowrap",
            }}
          >
            <Box>{startOver}</Box>
            <Box>
              <button
                className="btn-secondary-preference"
                onClick={handleClickOpen}
              >
                View Selected Preferences
                {/* <ArrowForwardIosIcon sx={{ fontSize: 14, ml: 0.5 }} /> */}
              </button>
              <BootstrapDialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
                fullWidth={true}
              >
                <BootstrapDialogTitle
                  id="customized-dialog-title"
                  onClose={handleClose}
                >
                  Selected preferences
                </BootstrapDialogTitle>
                <DialogContent dividers>
                  <PrefferedSelection
                    title="Age group"
                    info={answerState.age}
                  />
                  <PrefferedSelection
                    title="Symptoms & Conditions"
                    symptoms={answerState.symptoms}
                  />
                  <PrefferedSelection
                    title="Product Strength"
                    info={answerState.strength}
                  />
                  <PrefferedSelection
                    title="Relief Type"
                    info={answerState.reliefType}
                  />
                  <PrefferedSelection
                    title="Product Form"
                    info={answerState.productForm}
                  />
                </DialogContent>
              </BootstrapDialog>
            </Box>
          </Box>
          <Box>
            {(allProducts && allProducts.otherSymptomsProduct?.length! > 0) ||
            (allProducts &&
              allProducts.coldSoreProduct &&
              allProducts.coldSoreProduct.length > 0) ? null : (
              <h3 className="productSlider_heading">
                Unfortunately we couldn’t find any product that matched your
                preferencessss
              </h3>
            )}
            {currenScenario === UIScenario.COMBO && (
              <h3 className="productSlider_heading">
                Recommended Product combo(s) for you
              </h3>
            )}
            {currenScenario === UIScenario.ONLY_COLDSORE && (
              <h3 className="productSlider_heading">
                Recommended{" "}
                {allProducts &&
                allProducts.coldSoreProduct &&
                allProducts.coldSoreProduct?.length > 1
                  ? "Products"
                  : "Product"}{" "}
                for you
              </h3>
            )}
            {currenScenario === UIScenario.ONLY_OTHERSYMPTOMS && (
              <h3 className="productSlider_heading">
                Recommended{" "}
                {allProducts &&
                allProducts.otherSymptomsProduct &&
                allProducts?.otherSymptomsProduct?.length > 1
                  ? "Products"
                  : "Product"}{" "}
                for you
              </h3>
            )}

            <Box sx={{ marginTop: "-10px", marginBottom: "10px" }}>
              {answerState.hasOwnProperty("productForm") &&
                !(
                  allProducts &&
                  allProducts.otherSymptomsProduct &&
                  allProducts.otherSymptomsProduct.length > 0 &&
                  ((allProducts &&
                    allProducts.coldSoreProduct &&
                    allProducts.coldSoreProduct.length > 0) ||
                    (allProducts &&
                      allProducts.soreThroatProduct &&
                      allProducts.soreThroatProduct.length > 0))
                ) && (
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={showProductForm}
                          color="error"
                          onChange={() => setShowProductForms(!showProductForm)}
                        />
                      }
                      label="See other available product forms"
                      sx={{
                        color: "#717171",
                        fontFamily: "HealthCVS",
                        fontSize: "16px",
                        fontStyle: "normal",
                        fontWeight: 400,
                      }}
                    />
                  </FormGroup>
                )}
            </Box>
            {(allProducts && allProducts.otherSymptomsProduct?.length! > 0) ||
            (allProducts &&
              allProducts.coldSoreProduct &&
              allProducts.coldSoreProduct.length > 0)
              ? onMemo
              : ""}
          </Box>
          {/* Add ons */}
          {allFinalAddOns && allFinalAddOns?.length > 0 && (
            <Box
              sx={{
                overflow: "auto",
                width: "100%",
                background: "#ffffff",
                height: "100%",
              }}
            >
              <p className="productSlider_heading mt-1">
                Frequently Bought Together :
              </p>
              <Box sx={{ marginTop: "20px", overflow: "auto" }}>
                <Stack
                  flexWrap={{ xs: "wrap", sm: "wrap", md: "wrap" }}
                  direction={{ xs: "row", sm: "row", md: "row" }}
                  justifyContent={{ xs: "space-between", sm: "flex-start" }}
                  gap={2}
                >
                  {allFinalAddOns &&
                    allFinalAddOns.length > 0 &&
                    allFinalAddOns.map((value) => (
                      <Box
                        sx={{
                          border: "1px solid #E9E9E9",
                          width: { xs: "46%", sm: "164px" },
                        }}
                      >
                        <AddonCard key={value.productSku} product={value} />
                      </Box>
                    ))}
                </Stack>
              </Box>
            </Box>
          )}
        </Stack>

        <FooterLogo />
      </Box>
      <Modal
        open={openFeedbackPopup === "open"}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{
          display: "flex",
          flexDirection: "column",
          flexGrow: 1,
          height: "100%",
          padding: "20px 15px",
        }}
      >
        <FeedbackPopup
          setFeedbackPopup={setOpenFeedbackPopup}
          onSubmitFeedback={onSubmitFeedback}
        />
      </Modal>
    </>
  );
};

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(questionActions, dispatch);

const mapStateToProps = createStructuredSelector<GlobalState, StateProps>({
  allProducts: getAllRecommendedProducts,
  answerState: selectedAnswers,
  // loadInitialProductState1: loadInitialProductState,
});

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

/* MUI modal code  */
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props: DialogTitleProps) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: "black",
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};
/*--------*/

export default connect(mapStateToProps, mapDispatchToProps)(ProductPreference);
