import { Reducer } from "redux";
import * as questionTypes from "../questions/Types";

const INITIAL_STATE: questionTypes.QuestionState = {
  questions: [],
  currentQuestionID: 0,
  answerState: {},
};

const questionReducer: Reducer<questionTypes.QuestionState> = (
  state = INITIAL_STATE,
  action: any
) => {
  switch (action.type) {
    case questionTypes.QuestionActionsType.LOAD_INITIAL_STATE:
      const payload =
        action.payload as questionTypes.LoadInitialQuestionsPayload;
      return {
        ...state,
        questions: payload.questions,
        answerState: payload.answerState,
        currentQuestionID: payload.currentQuestionID
      };
    case questionTypes.QuestionActionsType.NEXT_QUESTION: {
      const payload = action.payload as questionTypes.LoadQuestionPayload;
      return {
        ...state,
        currentQuestionID: payload.questionId,
      };
    }
    case questionTypes.QuestionActionsType.SAVE_ANSWER: {
      const payload = action.payload as questionTypes.SaverAnswerPayload;
      const questionIndex = payload.questionId;
      const question = state.questions[questionIndex];
      const answer = state.answerState;
      question.selectedAnswers = payload.answers;
      switch (questionIndex) {
        case 0:
          answer.age = payload.answers[0];
          break;
        case 1:
          answer.symptoms = payload.answers;
          break;
        case 2:
          answer.strength = payload.answers[0];
          break;
        case 3:
          answer.reliefType = payload.answers[0];
          break;
        case 4:
          answer.productForm = payload.answers[0];
          break;
        default:
      }
      return {
        ...state,
        questions: {
          ...state.questions,
          [question.questionId]: {
            ...question,
            selectedAnswers: payload.answers,
            selectedId: payload.answerId
          },
        },
        answerState: answer,
      };
    }
    default:
      return state;
  }
};
export default questionReducer;
