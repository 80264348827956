import { Box } from "@mui/material";
import { useNavigate } from "react-router-dom";
import LogoIcon from "../../../assets/eyeQuestion/logo.png";
import { ROUTES } from "../../Routing/Routing";
const FooterLogo = () => {
  const navigate = useNavigate()
  const openInNewTab = () => {
    navigate(ROUTES.ROOT)
  };
  return (
    <Box
      width={"100%"}
      display="flex"
      justifyContent="center"
      alignItems="center"
      marginY="10px"
      flexDirection="column"
    >
      <img
        src={LogoIcon}
        alt="img"
        style={{
          width: "70px",
          height: "30px",
          objectFit: "contain",
        }}
      />
      <Box
        sx={{
          color: "#C00",
          fontFamily: "HealthCVS",
          fontSsize: "16px",
          fontStyle: "normal",
          fontWeight: 500,
          lineHeight: "normal",
          cursor:'pointer',
          marginTop:'5px'
        }}
        onClick={openInNewTab}
      >
        www.cvs.com/shopsimple
      </Box>
    </Box>
  );
};

export default FooterLogo;
