import addonsPlaceholder from "../../../assets/addOns.png";
import "./addoncard.css";
import Rating from "@mui/material/Rating";
import { Product } from "../../../ccf/questions/Types";
import { ROUTES } from "../../Routing/Routing";
import { useNavigate } from "react-router-dom";
import mixpanel from "mixpanel-browser";
import { Img } from "react-image";
import { Box, Stack } from "@mui/material";

type AddOnsProps = {
  product: Product;
};

export default function Addoncard(props: AddOnsProps) {
  let navigate = useNavigate();

  const handleAddOnsDetails = (selectedProduct: Product) => {
    mixpanel.track("add-to-cart-tapped", {
      type: "ccf",
      productSku: selectedProduct.productSku,
    });
    if (
      selectedProduct.productPageUrl &&
      selectedProduct.productPageUrl !== ""
    ) {
      // window.location.href = `${selectedProduct.productPageUrl}`
      window.open(`${selectedProduct.productPageUrl}`, "_blank");
    } else {
      alert("No product URL found");
    }
    //navigate(ROUTES.PRODUCTDETAILS, { state: selectedProduct });
  };

  return (
    <>
      <Stack
        sx={{
          
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          padding:'10px',       
        }}
        onClick={() => {
          mixpanel.track("add-ons-details-clicked", {
            type: "ccf",
            product: props.product.productSku,
          });
          handleAddOnsDetails(props.product);
        }}
      >
        <Box
          sx={{
            width: "100px",
            height: "120px",
            background: "#E9E9E9",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box>
            <Img
              src={[props.product.productImageURL, addonsPlaceholder]}
              alt="medicine"
              className="med_img"
            />
          </Box>
        </Box>
        <Box sx={{marginTop:'20px'}}>
          <Stack >
            <Box
              sx={{
                color: "#002744",
                fontFamily: "HealthCVS",
                fontSize: "16px",
                fontStyle: "normal",
                fontWeight: 500,
                lineHeight: "24px",
              }}
            >
              <p className="cardtitle">{props.product.productSkuShortName}</p>
            </Box>
            <Box>
            <Box sx={{color: "#8c8c8c",
                fontFamily: "HealthCVS",
                fontSize: "0.75rem",
                marginY:"5px",
                fontStyle: "normal",
                fontWeight: 400,
                lineHeight: "19px"}}>{props.product.productBrand}</Box>

              <p className="ccf-info-text text-grey-dark" style={{marginBottom:'5px'}}>
               Item #{props.product.productSku} 
              </p>
            </Box>
            {/* Rating component */}
          <Box sx={{ display: "flex", alignItems: "center",flexWrap:'wrap' }}>
            <Rating
              name="read-only"
              value={props?.product?.productRating ?? null}
              sx={{fontSize:"12px"}}
              className="rating rating-new"
              readOnly
            />
            <span className="itemsReview">
              {props?.product?.productRating.toFixed(1)} (
              {props?.product?.productReview ?? "No"} reviews)
            </span>
          </Box>
          {/* Rating component */}

          {/* Learm More  */}
          <Box
            sx={{
              color: "#F00",
              fontFamily: "HealthCVS",
              fontSize: "16px",
              fontStyle: "normal",
              fontWeight: 500,
              lineHeight: "24px",
              marginTop: "10px",
            }}
            onClick={()=>handleAddOnsDetails(props.product)}
          >
            Learn More
          </Box>
          {/* Learm More  */}
          </Stack>
        </Box>
      </Stack>
    </>
  );
}
