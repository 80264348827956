import AppHeader from "../Header/header";
import Box from "@mui/material/Box";
import Rating from "@mui/material/Rating";
import Carousel from "react-elastic-carousel";
import products from "../../../assets/products.png";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { FunctionComponent } from "react";
import "./productdetails.css";
import { Product } from "../../../ccf/questions/Types";
import React from "react";
import { useLocation } from "react-router-dom";
import mixpanel from "mixpanel-browser";
import parse from "html-react-parser";
import { Img } from 'react-image'
import addonsPlaceholder from "../../../assets/products.png";

type ProductProps = {
  product: Product;
};

const ProductDetails: FunctionComponent<ProductProps> = ({ product }) => {

  const { state } = useLocation();
  const selectedProduct = state as Product;
  mixpanel.track("ccf-product-details", {
    type:"ccf",
    productSKU: selectedProduct.productSku,
    productBrand: selectedProduct.productBrand,
    productName: selectedProduct.productSkuShortName,
  });

  const imgs = [{ id: 1 }, { id: 2 }, { id: 3 }];
  const allimgs = imgs.map((img) => (
    <Img
      key={img.id}
      src={[selectedProduct.productImageURL, addonsPlaceholder]}
      alt="product"
      width="40%"
      className="img"
    />
  ));

  const handleAddToCart = () => {
    mixpanel.track("add-to-cart-tapped", {type:"ccf", productSku: product.productSku });
    if (selectedProduct.productPageUrl && selectedProduct.productPageUrl !== "") {
      window.open(selectedProduct.productPageUrl);
    }
  };
  let productUses = "Not Available"
  if(selectedProduct.productDirection ===null){
      productUses = "Not Available"
  }
  else{
    productUses = selectedProduct.productDirection.substring(selectedProduct.productDirection.indexOf("{")+2,selectedProduct.productDirection.indexOf("}")-1)
  }
  return (
    <>
      <AppHeader
        loadQuestion={null}
        previousIdQuestion={null}
        isBackAllow={true}
      />
      <Box sx={{ m: 2 }}>
        <p className="productinfo">{selectedProduct.productBrand}</p>
        <h3 className="productDesc">
          {selectedProduct.productSkuShortName ?? "Not Available"}
        </h3>
        <div>
          <Rating
            name="read-only"
            value={selectedProduct.productRating ?? 0}
            sx={{fontSize:"12px"}}
            className="rating"
            readOnly
          />
          <span className="prodreviews">
            {selectedProduct.productReview ?? "No"} reviews
          </span>
        </div>
        <div className="carouselStyle">
          <Carousel itemsToShow={1.1} isRTL={false} showArrows={true}>
            {allimgs}
          </Carousel>
        </div>
        {/* <p className="prodavailable">
          {selectedProduct.productAvailability ? "Available" : "Unavailable"}
        </p> */}
        <p className="arriavalinfo">Typically arrives in 2-3 days</p>
        <p className="prodprice">${selectedProduct.productPrice ?? "N/A"}</p>
        <p className="skuStyle">Item # {selectedProduct.productSku}</p>

        {/* Accordion section */}
        <Accordion elevation={0} className="accordionWrapper">
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            className="custAccordion"
          >
            <div>Product Details</div>
          </AccordionSummary>
          <AccordionDetails className="custDetails">
            <div>{parse(selectedProduct.productDetails ?? "Not Available")}</div>
          </AccordionDetails>
        </Accordion>
        <hr />
        <Accordion elevation={0} className="accordionWrapper">
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            className="custAccordion"
          >
            <div>Product Uses</div>
          </AccordionSummary>
          <AccordionDetails className="custDetails">
            <div>{parse(productUses)}</div>
          </AccordionDetails>
        </Accordion>
      </Box>
      <div className="buttongroup">
        {/* <select name="cars" id="cars">
          <option value="01">Quantity:01</option>
          <option value="02">Quantity:02</option>
          <option value="03">Quantity:03</option>
          <option value="04">Quantity:04</option>
        </select> */}
        <button onClick={handleAddToCart}>Add to cart</button>
        {/* <ExpandMoreIcon className="selectIcon" /> */}
      </div>
      <Box sx={{ m: 2 }}>
        <Accordion elevation={0} className="accordionWrapper">
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            className="custAccordion"
          >
            <div>Warnings</div>
          </AccordionSummary>
          <AccordionDetails className="custDetails">
            <div>{parse(selectedProduct.productWarnings ?? "Not Available")}</div>
          </AccordionDetails>
        </Accordion>
        <hr />
      </Box>
    </>
  );
};

export default ProductDetails;
