import Box from "@mui/material/Box";
import cvslogo from "../../../assets/cvsLogoNew.svg";
import { LoadQuestionPayload } from "../../../ccf/questions/Types";
import "./header.css";
import React, { FunctionComponent } from "react";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../Routing/Routing";
import { useDispatch, useSelector } from "react-redux";
import { GlobalState } from "../../../app/store/global-state";
import { saveAnswer } from "../../../app/store/action";
import mixpanel from "mixpanel-browser";
import BackHeader from "../BackHeader";

type HeaderProps = {
  loadQuestion?: { (payload: LoadQuestionPayload): void } | null;
  previousIdQuestion?: number | null;
  isBackAllow: boolean | false;
};

const AppHeader: FunctionComponent<HeaderProps> = ({
  loadQuestion,
  previousIdQuestion,
  isBackAllow,
}) => {
  let navigate = useNavigate();
  let dispatch = useDispatch();
  const currentID = useSelector((state: GlobalState) => state.quest);
  let selectedID: number[] | undefined;
  if (currentID.currentQuestionID > 0) {
    selectedID =
      currentID.questions[currentID.currentQuestionID - 1].selectedId;
  }
  const navigateToPrevQuestion = (prevQuestionId: number) => {
    const prevQId = prevQuestionId;

    if (isBackAllow) {
      navigate(-1);
    } else if (prevQuestionId < 0) {
      navigate(ROUTES.ROOT);
    } else {
      loadQuestion!({ questionId: prevQId - 1 });
      dispatch(
        saveAnswer({
          questionId: prevQId - 1,
          answers: [],
          answerId: selectedID,
        })
      );
    }
  };

  function handleHome(e: any): void {
    mixpanel.track("ccf_header_clicked", { type: "ccf" });

    navigate(ROUTES.ROOT);
  }

  return (
    <>
      <div className="ccf-header">
        <div className="ccf-header-logo-container">
          <img
            src={cvslogo}
            alt="company-logo"
            className="cvs-logo"
            onClick={(e) => {
              handleHome(e);
            }}
          />
        </div>
        {(!!previousIdQuestion || previousIdQuestion === 0 || isBackAllow) && (
          <Box>
            <BackHeader
              handleClick={() =>
                navigateToPrevQuestion(
                  previousIdQuestion ? previousIdQuestion : -3
                )
              }
            />
          </Box>
        )}
      </div>
    </>
  );
};

export default AppHeader;
