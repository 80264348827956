import { Box } from "@mui/material";
import mixpanel from "mixpanel-browser";
import {useEffect, useState } from "react";
import BackHeader from "../../common/component/BackHeader";
import FooterLogo from "../../common/component/BottomLogo";
import ShowItem from "./showItem";

interface propsPayload {
  image: string;
  handleClick: any;
  areas: any;
}

const QuizComponent = ({ image, handleClick, areas }: propsPayload) => {
  const [quizClicked, setQuizClicked] = useState(false);

  useEffect(() => {
    mixpanel.track("cvs_landing_page_quiz_screen");
  }, [])
  
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
        justifyContent: "space-between",
      }}
    >
      <Box paddingX={"24px"} paddingBottom={"12px"}>
        {quizClicked && (
          <Box mb="18px">
            <BackHeader handleClick={() => setQuizClicked(false)} />
          </Box>
        )}
        {quizClicked ? (
          <Box>
            {/* <p className="selectCategoryTitle">What are you looking for?</p> */}
            <Box className="selectCategory">
          Select Category:
          </Box>
            <ShowItem data={areas} handleClick={handleClick} />
          </Box>
        ) : (
          <Box>
            <p className="heading">
            Take our 30-second Quiz for Personalized Wellness
            </p>
            <p className="subtitle">
            Answer a few questions to get matched with the best product for your needs.
            </p>
            <Box display="flex" justifyContent="center" marginY={"10px"}>
              <img src={image} alt="Pharmacist" className="pharmacist_img" />
            </Box>
            <Box display="flex" justifyContent="center">
              <button
                onClick={() => {setQuizClicked(true);mixpanel.track("start_quiz_clicked")}}
                className="button_red"
              >
                Take a quick quiz
              </button>
            </Box>
            <p className="disclaimerText">
              *The experience is for the purpose of education and informing the
              customer. It is not medical advice or to replace an examination by
              physician.
            </p>
          </Box>
        )}
      </Box>
      <FooterLogo />
    </Box>
  );
};

export default QuizComponent;
