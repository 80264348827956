export type Product = {
  status: boolean;
  comments?: number;
  ingredient1: string;
  ingredient2: string;
  ingredient3: string;
  ingredient4: string;
  isProductSugarFree: string;
  productAvailability: string;
  productBrand: string;
  productBrandPriority: string;
  productForAge: string;
  productForm: string;
  productPrice: number;
  productRecommendationType: string;
  productReliefType: string;
  productSku: number;
  productSkuShortName: string;
  productStrength: string;
  productImageURL: string;
  productWarnings: string;
  productReview: number;
  productRating: number;
  productDirection: string;
  productDetails: string;
  productPageUrl: string;
  symptom: string;
  productFormShortName:string
};

export type otherSymptomsProduct = { products: Product[] };
export type coldSoreProduct = { products: Product[] };
export type soreThroatProduct = { products: Product[] };


/*
    Actions types
*/
export enum QuestionActionsType {
  LOAD_INITIAL_STATE = "LOAD_INITIAL_STATE",
  SAVE_ANSWER = "SAVE_ANSWER",
  NEXT_QUESTION = "NEXT_QUESTION",
  SAVE_PRODUCTS = "SAVE_PRODUCTS",
  PRODUCT_INITIAL_STATE = "PRODUCT_INITIAL_STATE",
}
/*
    Reducer types
*/
export type Options = {
  id: number;
  value: string;
};

export type Question = {
  questionId: number;
  options: Options[];
  question: string;
  isMultiselectAllow: boolean;
  selectedAnswers?: string[];
  selectedId?:number[];
};

export type answerState = {
  age?: string;
  symptoms?: string[];
  strength?: string;
  reliefType?: string;
  productForm?: string;
  isSugarFree?: string;
  isFinalRequest?: boolean;
};

export type QuestionState = {
  questions: Question[];
  currentQuestionID: number;
  answerState: answerState;
};

export type ProductState = {
  otherSymptomsProduct?: Product[];
  coldSoreProduct?: Product[];
  soreThroatProduct?:Product[];
  isTwoSymptomsPartialMatch? : false;
};

/*
    Payload types
*/
export interface LoadInitialQuestionsPayload {
  currentQuestionID: 0;
  questions: Question[];
  answerState: answerState;
}

export interface LoadInitialProductsPayload {
  allProducts?: ProductState;
}

export interface LoadRcommendedProductPayload {
  recommendedProducts: ProductState;
}
export interface LoadQuestionPayload {
  questionId: number;
}

export interface SaverAnswerPayload {
  questionId: number;
  answers: string[];
  answerId?:number[];
}
