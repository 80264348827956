import React from "react";
import ReactDOM, { render } from "react-dom";
import "./index.css";
import App from "./App";
import { store } from "./app/store";
import { Provider } from "react-redux";
import * as serviceWorker from "./serviceWorker";
import { CookiesProvider } from "react-cookie";
import { asyncWithDVCProvider } from "@devcycle/devcycle-react-sdk";
// import { useCookies } from "react-cookie";
import { DEVCYCLE_ENV_KEY } from "./ccf/Utils/Constants";

(async () => {
  // const [cookies, setCookie] = useCookies();
 
  const DVCProvider = await asyncWithDVCProvider({ envKey: DEVCYCLE_ENV_KEY });

  ReactDOM.render(
    <React.StrictMode>
      <Provider store={store}>
        <CookiesProvider>
          <DVCProvider>
            <App />
          </DVCProvider>
        </CookiesProvider>
      </Provider>
    </React.StrictMode>,
    document.getElementById("root")
  );
})();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
