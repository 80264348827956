import { Product, ProductState, Question } from "../ccf/questions/Types";
import * as questionsData from "../assets/questions.json";
import axios from "axios";
import {
  BASE_API_URL,
  GETADDON_API_URL,
  GETEECENDPOINT,
  GETEECQUESTION,
  GETPRODUCT_API_URL,
} from "../ccf/Utils/Constants";
import { postApiRequest } from "./interceptor";

export const fetchQuestions = async (): Promise<Question[]> => {
  let data = questionsData;
  return data.questions.map((question: Question) => ({
    questionId: question.questionId,
    question: question.question,
    options: [...question.options],
    isMultiselectAllow: question.isMultiselectAllow,
    selectedAnswers: [],
    selectedId: [],
  }));
};

export const getProductRecommendations = async (
  finalRequest: any,
  isFinalRequest = true
): Promise<ProductState> => {
  const API_URL = BASE_API_URL + GETPRODUCT_API_URL;
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer adfoierfow48oeu983encnj0394rien",
  };
  try {
    finalRequest.isFinalRequest = isFinalRequest;
    const response = await axios({
      method: "post",
      url: API_URL,
      data: finalRequest,
      headers: headers,
    });
    return response.data;
  } catch (error) {
    console.log(error);
    return {};
  }
  return {};
};

export const getAddons = async (addOnRequest: any): Promise<Product[]> => {
  const API_URL = BASE_API_URL + GETADDON_API_URL;
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer adfoierfow48oeu983encnj0394rien",
  };
  try {
    const response = await axios({
      method: "post",
      url: API_URL,
      data: addOnRequest,
      headers: headers,
    });
    return response.data;
  } catch (error) {
    console.log("ERROR::", error);
    return [];
  }
  return [];
};


// fetch question for Eye Care next question
export const getNextQuestion = async (body: any) => {
  return postApiRequest({ url: GETEECQUESTION, body })
    .then((res: any) => res?.data?.data)
    .catch((err) => {throw err});
};

// fetch Url for products
export const fetchEyeEarDataApi = async (body: any) => {
  return postApiRequest({ url: GETEECENDPOINT, body })
    .then((res: any) => res?.data)
    .catch((err) => {throw err})
};