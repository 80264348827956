import { Box, Grid } from "@mui/material";
interface propsPayload {
  data: any;
  handleClick: any;
}
const ShowItem = ({ data, handleClick }: propsPayload) => {
  return (
    <Grid
      className="categoryItemContainer"
      sx={{
        display: "grid",
        "gridTemplateColumns": "repeat( auto-fit, minmax(150px, 1fr))",
      }}
    >
      {data?.map((item: any) => {
        return (
          <Box key={item?.id} className="categoryItems" onClick={()=>handleClick(item)} >
            <img
              src={item.icon}
              alt="item-icon"
              style={{
                width: "48px",
                height: "48px",
                objectFit: "contain",
              }}
            />
            <p>{item.label}</p>
          </Box>
        );
      })}
    </Grid>
  );
};

export default ShowItem;
