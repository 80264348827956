import { Box, Grid, Modal } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import store from "../app/store";
import ReusableHeader from "../common/component/Header/reusableheader";
import { Question } from "../common/component/Question/Question";
import { ROUTES } from "../common/Routing/Routing";
import { questions } from "./vitaminsQuestionnaire";
import {
  resetVitaminState,
  setAnswer,
  setSelectedOption,
  setUpdateSelectedOption,
  updateState,
  updateSymptoms,
} from "./VitaminsSilce";

let storeMultiSelectOptions: any = [];
export default function Vitamins() {
  console.log("render");
  let [question, setQuestion] = useState(questions[0]);
  let [isDisabled, setIsDisabled] = useState(true);
  console.log(isDisabled,"is diabled")
  let dispatch = useDispatch();
  const navigate = useNavigate();
  let questionRef = useRef<HTMLDivElement | null>(null);
  let questionsRef = useRef<HTMLDivElement | null>(null);

  
  function handleLogoClick() {
    // mixpanel.track("eec_header_clicked",{type:"eec"});
    dispatch(resetVitaminState())
    navigate(ROUTES.ROOT);
  }
  useEffect(() => {
    console.log("render useeffect");
    let vitamin_state = store.getState().vitamins;
    let visited = vitamin_state.visited
    console.log(visited,"use effect visited")
    if (questionsRef && questionsRef.current) {
      for (let i = 0; i < questionsRef.current.children.length; i++) {
        if (
          vitamin_state.selectedOption.includes(
            questionsRef.current.children[i].id
          )
        ) {
          questionsRef.current.children[i].className = "ee_question_active";
          dispatch(setUpdateSelectedOption());
        }
      }
      if (
        questionRef &&
        questionRef.current &&
        vitamin_state.answer.symptoms?.length > 0
      ) {
        for (let i = 0; i < questionsRef.current.children.length; i++) {
          if (
            vitamin_state.answer.symptoms.includes(
              questionsRef.current.children[i].innerHTML
            )
          ) {
            questionsRef.current.children[i].className = question.id === 4?
              "ee_question_grid_active":"ee_question_active";
               dispatch(updateSymptoms())
          }
        }
      }
    }
  }, [handleBack]);
//   function handleSingleSelectBack(){
//     let vitamin_state = store.getState().vitamins;
//     if (questionsRef && questionsRef.current) {
//           for (let i = 0; i < questionsRef.current.children.length; i++) {
//             if (
//               vitamin_state.selectedOption.includes(
//                 questionsRef.current.children[i].id
//               )
//             ) {
//               questionsRef.current.children[i].className = "ee_question_active";
//               dispatch(setUpdateSelectedOption());
//             }
//           }
//   }
// }

  function handleBack() {
    let vitamin_state = store.getState().vitamins.answer;
    if(question.id === 1){
       dispatch(updateState(question.id))
    }
    if(question.id === 4 || question.id=== 8){
      storeMultiSelectOptions=[]
      setIsDisabled(true)
    }
    if (question.previousQuestion === -1) {
      navigate(ROUTES.ROOT);
      return;
    }
    if (vitamin_state.concern === "Immunity" && question.id === 4) {
      setQuestion({ ...question, ...questions[7] });
      return;
    }
    setQuestion({ ...question, ...questions[question.previousQuestion] });
  }
  
  
  function handleNext(event: any) {
    let visited = store.getState().vitamins.visited
    if (!question.isMultiSelect) {
      let selection = { id: event.target.id, value: event.target.innerHTML };
      dispatch(setAnswer(selection));
      dispatch(setSelectedOption(selection.id));
      let nextQuestion = parseInt(
        event.target.getAttribute("data-nextquestion")
      );
      console.log(nextQuestion,"next")
       if(nextQuestion === -1){
          console.log("inside")
           navigate(ROUTES.VITPREFERENCE)
       }
       else{
        setQuestion({ ...question, ...questions[nextQuestion] });
       }  
     
    }
    if (question.isMultiSelect) {
      if(visited){
        if(questionsRef && questionsRef.current){
          for(let i =0; i<questionsRef.current.children.length;i++){
            questionsRef.current.children[i].className = question.id==4 ?"ee_question_grid":"ee_question"
          }
        }
       dispatch(updateState(question.id))
      }
      
        if (!storeMultiSelectOptions.includes(event.target.innerHTML)) {
          storeMultiSelectOptions.push(event.target.innerHTML);
        } else {
          storeMultiSelectOptions.splice(
            storeMultiSelectOptions.indexOf(event.target.innerHTML),
            1
          );
        }
        if (storeMultiSelectOptions.length > 0) {
          setIsDisabled(false);
        }
        if (storeMultiSelectOptions.length === 0) {
          setIsDisabled(true);
        }
        console.log(storeMultiSelectOptions)
    
     
    }
  }

  function handleMultiSelect() {
    let answer = { id: question.id, value: storeMultiSelectOptions };
    dispatch(setAnswer(answer));
    dispatch(updateState(question.id))
    if (question && question.nextQuestion) {
      setQuestion({ ...question, ...questions[question.nextQuestion] });
      storeMultiSelectOptions =[]
      setIsDisabled(true)
    }
  }
  const options = question.options.map((item: any) => {
    return (
      <Question
        key={item.id}
        class={question.id === 4 ? "ee_question_grid" : "ee_question"}
        id={item.id}
        nextquestion={item.nextQuestion}
        value={item.value}
        ref={questionRef}
        handleClick={handleNext}
      />
    );
  });
  return (
    <>
      <ReusableHeader handleBack={handleBack} handleHome={handleLogoClick} backArrow={true} />
      <Box sx={{ height: "100vh", padding: "15px" }}>
        <div className="ee_questioncard_container">
          <p className="ee_question_title">{question.question}</p>
          <p className="ee_question_subtitle">{question.instruction}</p>
        </div>
        <Grid container>
          <Grid item xs={12} sm={6}>
            <div
              className={
                question.id === 4
                  ? "ee_question_container"
                  : ""
              }
              ref={questionsRef}
            >
              {options}
            </div>
          </Grid>
        </Grid>
        {question.id === 4 || question.id === 8 ? (
          <Box
            sx={{
              width: "100%",
              bgcolor: "blue",
              position: "relative",
              marginBottom: "20px",
            }}
          >
            <button
              disabled={isDisabled}
              className="bottomButton"
              onClick={handleMultiSelect}
            >
              Next
            </button>
          </Box>
        ) : null}
      </Box>
    </>
  );
}
