import { Box } from '@mui/material'
import BackIcon from "../../../assets/Back.svg"
import './index.css'
interface propsPayload {
    handleClick: any;
  }
const BackHeader = ({handleClick} : propsPayload) => {
  return (
    <Box display='flex' alignItems="center" onClick={handleClick}>
            <img src={BackIcon} alt="Back" className="backIcon"/>
            <span className="backText">Back</span>
        </Box>
  )
}

export default BackHeader