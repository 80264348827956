import ProductCard from "../ProductCard/ProductCard"; //TODO:: TOBE Removed
import Box from "@mui/material/Box";
import "./productpreference.css";
import "./productpreference.css";
import AppHeader from "../Header/header";
import { FunctionComponent, useEffect } from "react";
import * as questionTypes from "../../../ccf/questions/Types";
import { connect, useDispatch } from "react-redux";
import * as questionActions from "../../../app/store/action";
import { bindActionCreators, Dispatch } from "redux";
import { createStructuredSelector } from "reselect";
import { GlobalState } from "../../../app/store/global-state";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import {
  getAllRecommendedProducts,
  selectedAnswers,
} from "../../../app/store/selectors";
import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { styled } from "@mui/material/styles";
import PrefferedSelection from "../Preferredselection/PreferedSelection";
import mixpanel from "mixpanel-browser";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../Routing/Routing";

//Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

// import required modules
import { Pagination } from "swiper";
import SingleProductSlider from "./SingleProductSlider";
import FooterLogo from "../BottomLogo";

interface StateProps {
  allProducts: questionTypes.ProductState;
  answerState: questionTypes.answerState;
}

type Props = StateProps;

const ProductComboPreference: FunctionComponent<Props> = ({
  allProducts,
  answerState,
}) => {
  useEffect(() => {}, [allProducts]);

  /*selected  Preferences dialog*/
  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => {
    mixpanel.track("View Selected Preference",{type:"ccf"});
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  let navigate = useNavigate();
  const dispatch = useDispatch();
  const handleStartOver = () => {
    //Rest state
    dispatch(
      questionActions.loadInitialState({
        questions: [],
        currentQuestionID: 0,
        answerState: {},
      })
    );
    mixpanel.track("ccf_start_over_clicked",{type:"ccf"});
    navigate(ROUTES.QUESTIONS);
  };

  let coldSoreSkus = allProducts.coldSoreProduct?.map((product) => {
    return product.productSku;
  });
  mixpanel.track("ccf_combo_recommended-products", {
    type:"ccf",
    Recommended: "COMBO_COLDSORE",
    ResultSKUs: coldSoreSkus,
    ReturnedProducts: coldSoreSkus?.length ?? 0,
  });
  let otherSkus = allProducts.otherSymptomsProduct?.map((product) => {
    return product.productSku;
  });
  mixpanel.track("ccf_combo_recommended-products", {
    type:"ccf",
    Recommended: "COMBO_OTHERSYMPTOMS",
    ResultSKUs: otherSkus,
    ReturnedProducts: otherSkus?.length ?? 0,
  });
  return (
    <>
      <AppHeader
        loadQuestion={null}
        previousIdQuestion={null}
        isBackAllow={true}
      />
      <Box sx={{ padding: "0 24px 15px", background: "#ffffff" }}>
        {/* <p className="info">
          {allProducts.otherSymptomsProduct?.length ?? 0} Products Found
        </p> */}
        {/* <h3 className="question">
          Top{" "}
          {allProducts.otherSymptomsProduct?.length ??
            0 + allProducts?.coldSoreProduct?.length! ??
            0}{" "}
          product(s) that match your selected preferences
        </h3> */}
        {/* Modal code  */}
        <Box
          sx={{
            display: "flex",
            margin: "15px 0px",
            flexWrap: "nowrap",
          }}
        >
          <Box>
            <button className="btn-primary-prefence" onClick={handleStartOver}>
              Start Over
            </button>
          </Box>
          <Box>
            <button
              className="btn-secondary-preference"
              onClick={handleClickOpen}
            >
              View Selected Preferences
            </button>
            <BootstrapDialog
              onClose={handleClose}
              aria-labelledby="customized-dialog-title"
              open={open}
              fullWidth={true}
            >
              <BootstrapDialogTitle
                id="customized-dialog-title"
                onClose={handleClose}
              >
                Selected preferences
              </BootstrapDialogTitle>
              <DialogContent dividers>
                <PrefferedSelection title="Age group" info={answerState.age} />
                <PrefferedSelection
                  title="Symptoms & Conditions"
                  symptoms={answerState.symptoms}
                />
                <PrefferedSelection
                  title="Product Strength"
                  info={answerState.strength}
                />
                <PrefferedSelection
                  title="Relief Type"
                  info={answerState.reliefType}
                />
                <PrefferedSelection
                  title="Product Form"
                  info={answerState.productForm}
                />
              </DialogContent>
            </BootstrapDialog>
          </Box>
        </Box>
        {/* First slider */}
        <div>
          {allProducts?.otherSymptomsProduct && (
            <SingleProductSlider products={allProducts.otherSymptomsProduct}/>
          )}
        </div>
         {/* Slider for Other Product  */}
        <div>
          {/* <hr style={{ border: "1px solid rgba(233, 233, 233, 0.54)" }} />  //TODO: TO BE REMOVED */} 
          <Box sx={{ marginBottom: "20px", marginTop: "10px" }}>
            <p className="ccf-question-heading productSlider_heading">{allProducts.coldSoreProduct![0].productRecommendationType}</p>
          </Box>
          <div className="carousel-swipper">
            {allProducts.coldSoreProduct && (
              <SingleProductSlider products={allProducts.coldSoreProduct}/>
            )}
          </div>
        </div>
        <FooterLogo/>
      </Box>
    </>
  );
};

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(questionActions, dispatch);

const mapStateToProps = createStructuredSelector<GlobalState, StateProps>({
  allProducts: getAllRecommendedProducts,
  answerState: selectedAnswers,
});

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

/* MUI modal code  */
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props: DialogTitleProps) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: "black",
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};
/*--------*/

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProductComboPreference);
