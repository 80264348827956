import { Product } from "../../../ccf/questions/Types";
import "./option.css";
import { useEffect, useRef } from "react";

//CCF Icons
import SeniorIcon from "../../../assets/eyeQuestion/ageQuestions/senior.svg";
import AdultIcon from "../../../assets/eyeQuestion/ageQuestions/adult.svg";
import ChildIcon from "../../../assets/eyeQuestion/ageQuestions/child.svg";
import InfantIcon from "../../../assets/eyeQuestion/ageQuestions/infant.svg";
import ToddlerIcon from "../../../assets/eyeQuestion/ageQuestions/toddler.svg";

import ExtraStrengthIcon from "../../../assets/eyeQuestion/allergyEyeCare/strong.svg";
import RegularIcon from "../../../assets/SymptomIcon/CCFIcon/strength/arm.svg";
import DayNNightIcon from "../../../assets/SymptomIcon/CCFIcon/strength/dayNNight.svg";
import DayTimeIcon from "../../../assets/SymptomIcon/CCFIcon/strength/dayTime.svg";
import NightIcon from "../../../assets/SymptomIcon/CCFIcon/strength/night.svg";

import LiquidIcon from "../../../assets/SymptomIcon/CCFIcon/strength/liquid.svg";
import LiquiIcon from "../../../assets/SymptomIcon/CCFIcon/strength/liqui.svg";
import PillsIcon from "../../../assets/SymptomIcon/CCFIcon/strength/pills.svg";
import PowderIcon from "../../../assets/SymptomIcon/CCFIcon/strength/powder.svg";
import SprayIcon from "../../../assets/earQuestion/spray.svg";
import DropsIcon from "../../../assets/earQuestion/drops.svg";
import NoPreference from "../../../assets/earQuestion/pill.svg";
import { Box } from "@mui/material";
//CCF Icons

export default function ChooseOne(props: any) {
  const products: Product[] = props.products;
  const inputRef = useRef<HTMLInputElement | null>(null);
  useEffect(() => {
    if (props.selectId !== undefined) {
      for (let val of props.selectId) {
        if (val === props.value) {
          if (inputRef.current !== null) {
            inputRef.current.checked = true;
          }
        }
      }
    }
  }, [inputRef]);
  function options() {
    let LiqiCapGelArray = [
      "LIQUI CAPS",
      "LIQUI GEL",
      "LIQUI CAPS/LIQUI GEL",
      "GEL CAPS/LIQUID GEL",
      "LIQUI CAPS/LIQUI GEL/SOFT GEL",
    ];
    let TabletPillsArray = [
      "TABLET",
      "PILLS/TABLETS/CAPLETS",
      "CAPLETS",
      "PILLS",
      "PILLS/TABLETS",
      "PILLS/TABLETS/CAPLETS/CAPSULES",
    ];

    let mixWithWaterArray = [
      "DISSOLVABLE POWDER PACKET/MIX WITH WATER/EFFERVESCENT TABLET/GRANULE",
    ];

    switch (props.value) {
      case 14: // Max Strength/Severe
        let maxStrength = products.filter(
          (product) =>
            product.productStrength === "MAXIMUM STRENGTH/EXTRA STRENGTH/SEVERE"
        );
        if (maxStrength.length > 0) {
          return <QuestionItems />;
        } else {
          return;
        }
      case 15: // Regular Strength
        let regularStrength = products.filter(
          (product) => product.productStrength === "REGULAR STRENGTH"
        );
        if (regularStrength.length > 0) {
          return <QuestionItems />;
        } else return;
      case 16: // I dont know/Unspecified
        let maxStrength1 = products.filter(
          (product) =>
            product.productStrength === "MAXIMUM STRENGTH/EXTRA STRENGTH/SEVERE"
        );
        let regularStrength1 = products.filter(
          (product) => product.productStrength === "REGULAR STRENGTH"
        );
        if (regularStrength1.length <= 0 || maxStrength1.length <= 0) {
          return;
        } else {
          return <QuestionItems />;
        }
      case 17: // Day and Night Combo
        let dayNightCombo = products.filter(
          (product) => product.productReliefType === "DAY AND NIGHT COMBO"
        );
        if (dayNightCombo.length > 0) {
          return <QuestionItems />;
        } else return;
      case 18: // Night Time
        let nitetime = products.filter(
          (product) => product.productReliefType === "NIGHTTIME"
        );

        if (nitetime.length > 0) {
          return <QuestionItems />;
        } else return;
      case 19: // Day Time/Any Time
        let dayAnytime = products.filter(
          (product) => product.productReliefType === "DAY/ANYTIME"
        );

        if (dayAnytime.length > 0) {
          return <QuestionItems />;
        } else return;
      case 20: // Pills/Tablets
        let pills = products.filter(
          (product) => TabletPillsArray.includes(product.productForm) // === "Pills/Tablets"
        );
        if (pills.length > 0) {
          return <QuestionItems />;
        } else return;
      case 21: // Liquid/Syrup
        let liquid = products.filter(
          (product) => product.productForm === "LIQUID/SYRUP"
        );
        if (liquid.length > 0) {
          return <QuestionItems />;
        } else return;
      case 22: // Hot Liquid Or Powder Form
        let hotLiquid = products.filter(
          (product) => mixWithWaterArray.includes(product.productForm) // === "Hot Liquid Or Powder Form"
        );
        if (hotLiquid.length > 0) {
          return <QuestionItems />;
        } else return;
      case 23: // Gel Caps/Liquid Gel
        let gel = products.filter(
          (product) => LiqiCapGelArray.includes(product.productForm) // === "Gel Caps/Liquid Gel"
        );
        if (gel.length > 0) {
          return <QuestionItems />;
        } else return;
      case 24: // Spray
        let spray = products.filter(
          (product) => product.productForm === "SPRAY/PUMP/MIST"
        );
        if (spray.length > 0) {
          return <QuestionItems />;
        } else return;
      case 25: // No Preference
        let pills1 = products.filter(
          (product) => TabletPillsArray.includes(product.productForm) // === "Pills/Tablets"
        );
        let liquid1 = products.filter(
          (product) => product.productForm === "LIQUID/SYRUP"
        );
        let gel1 = products.filter(
          (product) => LiqiCapGelArray.includes(product.productForm) // === "Gel Caps/Liquid Gel"
        );
        let hotLiquid1 = products.filter(
          (product) => mixWithWaterArray.includes(product.productForm) // === "Hot Liquid Or Powder Form"
        );
        let spray1 = products.filter(
          (product) => product.productForm === "SPRAY/PUMP/MIST"
        );
        let productFormCount = 0;
        gel1.length > 0 && productFormCount++;
        hotLiquid1.length > 0 && productFormCount++;
        liquid1.length > 0 && productFormCount++;
        pills1.length > 0 && productFormCount++;
        spray1.length > 0 && productFormCount++;
        if (productFormCount > 1) {
          return <QuestionItems value={pills1} />;
        } else return;

      case 26: // DROPS/LOZENGES
        let DROPS = products.filter(
          (product) => product.productForm === "DROPS/LOZENGES"
        );
        if (DROPS.length > 0) {
          return <QuestionItems />;
        } else return;

      default:
        return <AgeQuestions />;
    }
  }

  const QuestionItems = ({ value }: any) => {
    let imgUrl = "";
    if (props.value === 14) {
      imgUrl = ExtraStrengthIcon;
    } else if (props.value === 15) {
      imgUrl = RegularIcon;
    } else if (props.value === 17) {
      imgUrl = DayNNightIcon;
    } else if (props.value === 18) {
      imgUrl = NightIcon;
    } else if (props.value === 19) {
      imgUrl = DayTimeIcon;
    } else if (props.value === 20) {
      imgUrl = PillsIcon;
    } else if (props.value === 21) {
      imgUrl = LiquidIcon;
    } else if (props.value === 22) {
      imgUrl = PowderIcon;
    } else if (props.value === 23) {
      imgUrl = LiquiIcon;
    } else if (props.value === 24) {
      imgUrl = SprayIcon;
    } else if (props.value === 25) {
      imgUrl = NoPreference;
    } else if (props.value === 26) {
      imgUrl = DropsIcon;
    }
    return (
      <Box
        className="singleSelect"
        sx={{
          alignItems: "center",
          borderRadius: "16px",
          padding: "10px 10px",
        }}
      >
        <img src={imgUrl} alt={imgUrl} />
        <Box className="singleSelectText">{props.label}</Box>
      </Box>
    );
  };

  const AgeQuestions = () => {
    let imgUrl = "";
    if (props.value === 1) {
      imgUrl = SeniorIcon;
    } else if (props.value === 2) {
      imgUrl = AdultIcon;
    } else if (props.value === 3) {
      imgUrl = ChildIcon;
    } else if (props.value === 4) {
      imgUrl = InfantIcon;
    } else if (props.value === 5) {
      imgUrl = ToddlerIcon;
    }
    return (
      <Box
        className="singleSelect"
        sx={{
          justifyContent: "space-between",
          display: "flex",
          alignItems: "center",
          borderRadius: "16px",
          height: "70px",
        }}
      >
        <Box sx={{ width: "15%" }}>
          <img
            src={imgUrl}
            alt={imgUrl}
            style={{ width: "40px", height: "40px", objectFit: "contain" }}
          />
        </Box>
        <Box className="singleSelectText">{props.label}</Box>
      </Box>
    );
  };

  return (
    <>
      <label htmlFor="" className="wrapper">
        <input
          type="checkbox"
          name="radio1"
          className="radioBtn"
          id={props.value}
          key={props.id}
          value={props.label}
          onChange={() => props.handleClick(props.value, props.label)}
          ref={inputRef}
        />
        {options()}
      </label>
    </>
  );
}
