import { createSlice } from "@reduxjs/toolkit";

const initialState: any = {
  feedbackSubmited: false,
  userDroppedAt: null,
  question: {},
  userChoice: {
    caretype: 0,
    patientprofile: 0,
    producttype: 0,
    input1: 0,
    input2: 0,
    input3: 0,
    input4: 0,
    user_experiment: 0
  },
  reqQuestion: {
    queId: null,
    optionId: null,
    age: null,
  },
  loggedIn: false,
  previousQuestion: [],
  previousReq: [],
  trackUserChoice: [],
};
const eeCareSilce = createSlice({
  name: "eeCare",
  initialState,
  reducers: {
    setUserDropTime:(state, action) => {
      state.userDroppedAt = action.payload;
    },
    setLogIn: (state, action) => {
      state.loggedIn = action.payload;
    },
    setQuestion: (state, action) => {
      state.question = action.payload;
    },
    setReqQuestionId: (state, action) => {
      state.reqQuestion.queId = action.payload;
    },
    setReqOptionId: (state, action) => {
      state.reqQuestion.optionId = action.payload;
    },
    setReqAge: (state, action) => {
      state.reqQuestion.age = action.payload;
    },
    setReqQuestion: (state, action) => {
      state.reqQuestion = action.payload;
    },
    saveUserChoice: (state, action) => {
      const productType = [3, 7, 11];
      const jqualifier1 = [4, 5, 6, 9, 13, 14];
      const jqualifier2: any = [12, 10];
      const jqualifier3: any = [8];
      const jqualifier4: any = [];

      if (action.payload === 18) {
        state.userChoice.caretype = 2;
        return;
      }

      if (state.question.que[0].id === 1) {
        state.userChoice.caretype = action.payload;
      }
      if (state.question.que[0].id === 2) {
        state.userChoice.patientprofile = action.payload;
      }
      if (productType.includes(state.question.que[0].id)) {
        state.userChoice.producttype = action.payload;
      }
      if (jqualifier1.includes(state.question.que[0].id)) {
        state.userChoice.input1 = action.payload;
      }
      if (jqualifier2.includes(state.question.que[0].id)) {
        state.userChoice.input2 = action.payload;
      }
      if (jqualifier3.includes(state.question.que[0].id)) {
        state.userChoice.input3 = action.payload;
      }
      if (jqualifier4.includes(state.question.que[0].id)) {
        state.userChoice.input4 = action.payload;
      }
    },
    reset: (state) => {
      state.isLoading = false;
      state.question = {};
      state.userChoice = {
        caretype: 0,
        patientprofile: 0,
        producttype: 0,
        input1: 0,
        input2: 0,
        input3: 0,
        input4: 0,
        user_experiment: 1,
      };
      state.reqQuestion = {
        queId: null,
        optionId: null,
        age: null,
      };
      state.loggedIn = false;
      state.previousQuestion = [];
      state.previousReq = [];
      state.trackUserChoice = [];
      state.feedbackSubmited = false;
    },
    setExperience: (state, action) => {
      state.userChoice.user_experiment = action.payload;
    },
    setPreviousQuestion: (state, action) => {
      state.previousQuestion.push(action.payload);
    },
    setUpdatePreviousQuestion: (state) => {
      state.previousQuestion.pop();
    },
    setPrevReq: (state, action) => {
      state.previousReq.push(action.payload);
    },
    setUpdatePrevReq: (state) => {
      state.previousReq.pop();
    },
    setTrackUserChoice: (state) => {
      state.trackUserChoice.push(state.userChoice);
    },
    setUpdateUserchoice: (state) => {
      state.trackUserChoice.pop();
    },
    setUserChoice: (state, action) => {
      state.userChoice = action.payload;
    },
    setFeedbackSubmited: (state, action) => {
      state.feedbackSubmited = action.payload;
    },
    setInitializeEyedata: (state) => {
      state.question = state.previousQuestion[0];
      state.reqQuestion = {
        queId: null,
        optionId: null,
        age: null,
      };
      state.previousQuestion = [];
      state.previousReq = [];
      state.trackUserChoice = [state.trackUserChoice[0]];
      state.userChoice = {
        ...state.userChoice,
        patientprofile: 0,
        producttype: 0,
        input1: 0,
        input2: 0,
        input3: 0,
        input4: 0,
      };
    },
    setInitializeEardata: (state) => {
      state.question = state.previousQuestion[0];
      state.reqQuestion = {
        queId: 1,
        optionId: 4,
        age: null,
      };
      state.trackUserChoice = [state.trackUserChoice[0]];
      state.userChoice = {
        ...state.userChoice,
        patientprofile: 0,
        producttype: 0,
        input1: 0,
        input2: 0,
        input3: 0,
        input4: 0,
      };
      state.previousQuestion = [];
      state.previousReq = [];
    },
  },
});
export const {
  setQuestion,
  setReqQuestionId,
  setReqOptionId,
  setReqAge,
  saveUserChoice,
  reset,
  setLogIn,
  setExperience,
  setPreviousQuestion,
  setUpdatePreviousQuestion,
  setPrevReq,
  setUpdatePrevReq,
  setReqQuestion,
  setTrackUserChoice,
  setUpdateUserchoice,
  setUserChoice,
  setFeedbackSubmited,
  setInitializeEyedata,
  setInitializeEardata,
  setUserDropTime
} = eeCareSilce.actions;
export default eeCareSilce.reducer;
