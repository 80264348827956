import "./productcard.css";
import medicinePlaceholder from "../../../assets/products.png";
import { Product } from "../../../ccf/questions/Types";
import { Img } from "react-image";
import { Box } from "@mui/material";
import AddIcon from "../../../assets/productPreference/AddIcon.svg";
type ProductProps = {
  otherSymptomProduct: Product;
  coldSoreProduct: Product;
  handleComboLoadMore?: () => void;
};
type ProductComponentProps = {
  label: String;
  imgUrls: any;
  productBrand: String;
};
const ComboProductComponent = ({ label, imgUrls,productBrand }: ProductComponentProps) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Img src={imgUrls} alt="medicine" className="med_img" />
      </Box>
      <Box
        sx={{
          color: "#002744",
          fontFamily: "HealthCVS",
          fontSize: "16px",
          fontStyle: "normal",
          fontWeight: 500,
          lineHeight: "24px",
          overflow: "hidden",
          textOverflow: "ellipsis",
          display: "-webkit-box",
          WebkitLineClamp: "2",
          WebkitBoxOrient: "vertical",
        }}
      >
        {label}
      </Box>
      <Box
        sx={{
          color: "#262626",
          fontFamily: "HealthCVS",
          fontSize: "12px",
          fontStyle: "normal",
          fontWeight: "400",
          lineHeight: "19px",
          letterSpacing: "-0.24px",
        }}
      >
        {productBrand}
      </Box>
    </Box>
  );
};

export default function ProductComboCard(props: ProductProps) {
  return (
    <>
      <Box
        sx={{
          borderRadius: "4px",
          border: "1px solid  #CC0000",
          padding: "13px",
        }}
      >
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: "2fr 1fr 2fr",
          }}
        >
          <ComboProductComponent
            label={props.otherSymptomProduct.productSkuShortName}
            imgUrls={[
              props.otherSymptomProduct.productImageURL,
              medicinePlaceholder,
            ]}
            productBrand={props.otherSymptomProduct.productBrand}
          />
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              src={AddIcon}
              alt={"ADDIcon"}
              style={{ width: "30px", height: "30px", objectFit: "contain" }}
            />
          </Box>
          <ComboProductComponent
            label={props.coldSoreProduct.productSkuShortName}
            imgUrls={[
              props.coldSoreProduct.productImageURL,
              medicinePlaceholder,
            ]}
            productBrand={props.coldSoreProduct.productBrand}
          />
        </Box>

        <Box
          sx={{
            color: "#F00",
            textAlign: "center",
            fontFamily: "HealthCVS",
            fontSize: "16px",
            fontStyle: "normal",
            fontWeight: 500,
            lineHeight: "24px",
            marginTop: "20px",
          }}
          onClick={props.handleComboLoadMore}
        >
          Learn More
        </Box>
      </Box>
    </>
  );
}
