
import { Box } from '@material-ui/core';
interface propsPayload {
  question: any;
  ee_state: any;
  handleClick: any;
}

const EEItemCard = ({ question, ee_state, handleClick }: propsPayload) => {
  // Add class for active and inActive elements dynamically
  const isGrid =  question && question.que && question.que[0].id === 3 && (ee_state.userChoice.caretype === 1 || ee_state.userChoice.caretype === 2)
  const attachClassForActiveItem = (option: any) => {
    let className = "";

    if (parseInt(ee_state.reqQuestion.optionId) === parseInt(option.id)) {
      if (
        ee_state.reqQuestion.optionId >= 10 &&
        ee_state.reqQuestion.optionId < 20
      ) {
        className = "ee_question_grid_active ";
      } else {
        className = "ee_question_active ";
      }
    }
    if (parseInt(ee_state.reqQuestion.age) === parseInt(option.id)) {
      className = className + "ee_question_active ";
    }
    const questionType =
      question &&
        question.que &&
        question.que[0].id === 3 &&
        (ee_state.userChoice.caretype === 1 || ee_state.userChoice.caretype === 2)
        ? "ee_question_grid "
        : "ee_question ";
    return className + questionType;
  };

  return (
    <div className={
      question &&
        question.que &&
        question.que[0].id === 3 &&
        (ee_state.userChoice.caretype === 1 ||
          ee_state.userChoice.caretype === 2)
        ? "ee_question_container"
        : ""
    }>
      {
        (question && question.option) && question.option.map((option: any) => {
          // Moved Ear care to home screen. Ear care is no more part of eye care
          if(option.id=== 4){
            return ""
          }
          const className = attachClassForActiveItem(option);
          return (
            <div
              id={option.id}
              key={option.id}
              className={className}
              onClick={()=>handleClick(option)}
            >
              {option?.icon && <Box mr={isGrid ? 0 :2} display="flex" alignItems={'center'} >
                <img
                  src={option?.icon}
                  alt="img"
                  style={{
                    width: isGrid ? "40px" : "35px",
                    height: isGrid ? "40px" : "35px",
                    objectFit: "contain",
                  }}
                />
              </Box>}
              <span>{option?.option}</span>
            </div>
          );
        })
      }
    </div>
  )
}


export default EEItemCard