import { Link, useLocation, useNavigate } from "react-router-dom";
import shopicon from "./../../../assets/disclaimerlogo.svg";
import AppHeader from "../Header/header";
import "./reusabledisclaimer.css";
import mixpanel from "mixpanel-browser";
import { Box, Grid, Stack } from "@mui/material";
import bgImage from "../../../assets/disclaimer.jpg";
import bgImagelarge from "../../../assets/disclaimer_large.jpg";
import disclaimerLandscape from "../../../assets/disclaimer_landscape.png";


type disclaimerProps = {
  description: string;
  buttonText: string;
  route: string;
  nextQuestion?: number;
  cancel: string;
};
const ReusableDisclaimer = (props: disclaimerProps) => {
  let navigate = useNavigate();
  const { state } = useLocation();
  const disclaimer = state as disclaimerProps;

  return (
    <>
      <AppHeader
        loadQuestion={null}
        previousIdQuestion={null}
        isBackAllow={false}
      />
      {/* small screen */}
      <Box sx={{display: { sm: "none" , overflow:"scroll"}}}>
      <Box
        sx={{
          background: `url(${bgImage})`,
          height:"100vh",
          padding: "15px",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover"
        }}
      >
        <Stack gap={1.5} sx={{overflowY:'auto',height:"90%"}} justifyContent="center">
          <Box>
            <p className="ccf-question-heading text-primary">Disclaimer:</p>
          </Box>
          <Box>
            <p className="disclaimer_desc"> {disclaimer.description}</p>
          </Box>
          <Box>
            <button
              className="btn-primary"
              onClick={() => {
                navigate(disclaimer.route);
              }}
            >
              {props.buttonText}
            </button>
            <button className="btn-secondary">
              {
                <Link
                  to={disclaimer.cancel}
                  style={{
                    color: "#CC0000",
                  }}
                >
                  Cancel
                </Link>
              }
            </button>
          </Box>
          <Box>
            <img src={shopicon} alt="logo" />
          </Box>
        </Stack>
      </Box>
      </Box>
     
      {/* medium to large */}
      <Box
        sx={{
          height: "100vh",
          display: { xs: "none", sm: "block",md:'none' },
          background: "#ffffff",
        }}
      >
        <Box
          sx={{
            width: "100%",
            height: "45%",
            backgroundImage: `url(${bgImagelarge})`,
            backgroundRepeat:'no-repeat',
            backgroundSize: "cover",
          }}
        ></Box>
        <Stack gap={2} sx={{ padding: "15px",height:"55%" ,overflowY:'auto' }}>
          <Box>
            <p className="ccf-question-heading text-primary">Disclaimer:</p>
          </Box>
          <Box>
            <p className="disclaimer_desc"> {disclaimer.description}</p>
          </Box> 
          <Box>
            <button
              className="btn-primary"
              onClick={() => {
                navigate(disclaimer.route);
              }}
            >
              {props.buttonText}
            </button>
            <button className="btn-secondary">
              {
                <Link
                  to={disclaimer.cancel}
                  style={{
                    color: "#CC0000",
                  }}
                >
                  Cancel
                </Link>
              }
            </button>
          </Box>
          <Box>
            <img src={shopicon} alt="logo" />
          </Box>
        </Stack>
      </Box>
      {/* Landscape disclaimer */}
      <Box
        sx={{
          display: {xs:'none',sm:'none', md: "block" },
          background: "#ffffff",
          height: "100vh",
        }}
      >
        <Grid container>
          <Grid item md={6}>
            <Box
              sx={{
                backgroundImage: `url(${disclaimerLandscape})`,
                backgroundRepeat:'no-repeat',
                backgroundSize:'cover',
                height: "100vh",
                width: "100%",
                backgroundPosition: "center",
              }}
            ></Box>
          </Grid>
          <Grid item md={6}>
            <Box
              sx={{
                height: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Stack  sx={{ width: "75%" }} gap={2.5}>
              <Box>
            <p className="ccf-question-heading text-primary">Disclaimer:</p>
          </Box>
          <Box>
            <p className="disclaimer_desc"> {disclaimer.description}</p>
          </Box>
          <Box>
            <button
              className="btn-primary"
              onClick={() => {
                navigate(disclaimer.route);
              }}
            >
              {props.buttonText}
            </button>
            <button className="btn-secondary">
              {
                <Link
                  to={disclaimer.cancel}
                  style={{
                    color: "#CC0000",
                  }}
                >
                  Cancel
                </Link>
              }
            </button>
          </Box>
          <Box>
            <img src={shopicon} alt="logo" />
          </Box>
              </Stack>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default ReusableDisclaimer;
