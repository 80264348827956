import { Reducer } from "redux";
import * as questionTypes from "./Types";

const INITIAL_STATE: questionTypes.ProductState = {
    otherSymptomsProduct : [],
    coldSoreProduct : []
};

const productReducer: Reducer<questionTypes.ProductState> = (
  state = INITIAL_STATE,
  action: any
) => {
  switch (action.type) {
    case questionTypes.QuestionActionsType.PRODUCT_INITIAL_STATE:
      return {
        ...state,
        coldSoreProduct: [],
        otherSymptomsProduct:[],
      };
    case questionTypes.QuestionActionsType.SAVE_PRODUCTS:{
            const payload = action.payload;
            return {
                ...state,
                coldSoreProduct: payload.coldSoreProduct || payload.soreThroatProduct,
                soreThroatProduct: payload.soreThroatProduct,
                otherSymptomsProduct: payload.otherSymptomsProduct,
            }
        }
    default:
      return state;
  }
};
export default productReducer;
