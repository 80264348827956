import React from "react";
import { useState } from "react";

export const Question = React.forwardRef((props: any, ref: any) => {
  const [selected, setSelected] = useState(false);

  return (
    <div
      id={props.id}
      data-nextquestion={props.nextquestion}
      className={props.class}
      ref={ref}
      style={{ border: selected ? "1px solid red" : "" }}
      onClick={(event) => {
        props.handleClick(event);
        setSelected(!selected);
      }}
    >
      {props.value}
    </div>
  );
});
