import { Box } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import happiestFace from "../../../assets/feedbackForm/happiest.png";
import saddestFace from "../../../assets/feedbackForm/sadFace.png";
import sadFace from "../../../assets/feedbackForm/sad.png";
import neutralFace from "../../../assets/feedbackForm/Neutral.png";
import hapyFace from "../../../assets/feedbackForm/happy.png";
import { useState } from "react";
interface feedbackProps {
  setFeedbackPopup?: any;
  onSubmitFeedback: any;
}
const feedbackOptions = [
  { id: 1, icon: saddestFace, title: "Worst" },
  { id: 2, icon: sadFace, title: "Bad" },
  { id: 3, icon: neutralFace, title: "Neutral" },
  { id: 4, icon: hapyFace, title: "Good" },
  { id: 5, icon: happiestFace, title: "Execellent" },
];

const FeedbackPopup = ({
  setFeedbackPopup,
  onSubmitFeedback,
}: feedbackProps) => {
  const [selectedItem, setSelectedItem] = useState({ id: 0, title: "" });
  return (
    <Box sx={{ backgroundColor: "#FFF", borderRadius: "5px" }}>
      <Box
        display="flex"
        justifyContent="flex-end"
        padding="10px"
        onClick={() => setFeedbackPopup("close")}
      >
        <CloseIcon sx={{ color: "#CC0000", fontSize: "30px" }} />
      </Box>
      <Box sx={{ padding: "24px 20px" }}>
        <Box
          sx={{
            color: "#000",
            fontFamily: "HealthCVS",
            fontSize: "22px",
            fontStyle: "normal",
            fontWeight: 400,
            lineHeight: "normal",
          }}
        >
          How was your experience?
        </Box>

        <Box sx={{ display: "flex", flexDirection: "row", marginTop: "10px" }}>
          {feedbackOptions?.map((item) => {
            return (
              <Box onClick={() => setSelectedItem(item)} key={item.id}>
                <img
                  src={item?.icon}
                  alt="smilling-img"
                  style={{
                    width: "50px",
                    height: "50px",
                    objectFit: "contain",
                    filter:
                      selectedItem?.id === item?.id
                        ? "opacity(100%)"
                        : "opacity(60%)",
                  }}
                />
              </Box>
            );
          })}
        </Box>
        <Box
          sx={{
            color: "#DDD",
            fontSize: "22px",
            fontWeight: 500,
            fontStyle: "italic",
            fontFamily: "HealthCVS",
          }}
        >
          Choose your experience
        </Box>
        <Box
          sx={{ display: "flex", marginTop: "30px", justifyContent: "center" }}
        >
          <button
            className="default-btn btn-secondary-new"
            onClick={() => setFeedbackPopup("close")}
          >
            cancel
          </button>
          <button
            onClick={() => onSubmitFeedback(selectedItem?.title)}
            className="default-btn btn-primary-new"
            disabled={!selectedItem?.title}
          >
            Submit
          </button>
        </Box>
      </Box>
    </Box>
  );
};

export default FeedbackPopup;
