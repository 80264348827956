import { Box } from '@mui/material'
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import ReusableHeader from '../common/component/Header/reusableheader'
import { ROUTES } from '../common/Routing/Routing';
import { resetVitaminState } from './VitaminsSilce';

export default function VitaminsPreference() {
  const dispatch = useDispatch()
  const navigate = useNavigate();


    function handleStartOver(){
      dispatch(resetVitaminState())
      navigate(ROUTES.ROOT);
    }

    const startOver = (
        <div>
          <button className="btn-primary-prefence" onClick={handleStartOver}>
            Start Over
          </button>
        </div>
      );
  return (
    <>
    <ReusableHeader backArrow={false} />
    <Box sx={{ height: "100vh", p:2  }}>
     <Box>
     <p className='question'>
    product(s) that match your selected preferences
    </p>
     </Box>
     <Box
              sx={{
                display: "flex",
                margin: "15px 0px",
                flexWrap: "nowrap",
              }}
            >
        <Box>
        {startOver}
        </Box>
        <Box>
        <button
                  className="btn-secondary-preference"
                  //onClick={}
                >
                  View Selected Preferences
                  {/* <ArrowForwardIosIcon sx={{ fontSize: 14, ml: 0.5 }} /> */}
                </button>
        </Box>
    </Box>
    </Box>
    </>
    
  )
}
