import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DoneIcon from '@mui/icons-material/Done';
import './preferredselection.css'



type selection = {
    title?:string,
    info?:string,
    symptoms?:string[],
    noPreference?:string[]
}

export default function PreferredSelection(props:selection){
    const style={
        border: "1px solid #cc0000",
        display:"flex",
        gap:"5px",
        padding:"7px",
        width:"max-content",
        marginRight:"4px",
        marginTop:"4px",
        fontSize:"0.8rem",
        borderRadius:"4px",
        font:"HealthCVSNormal"
    }

    return(
        <>
        <Accordion  elevation={0} className="accordionWrapper">
        <AccordionSummary
            expandIcon={<ExpandMoreIcon sx={{color:'black'}}/>}
            aria-controls="panel1a-content"
            id="panel1a-header"
            className="custAccordion"
        >
            <p>{props.title}</p>
        </AccordionSummary>
        <AccordionDetails className="custDetails">
          {props.info && !props.noPreference &&
           <div style={style}>
               <div > <DoneIcon sx={{fontSize:15}}/></div>
                <div>{props.info}</div>
           </div> }
           <div style={{display:"flex",flexWrap:"wrap"}}>
           {props.symptoms?.map(symptom =>
           <div key ={symptom} style={style}>
               <div style={{fontSize:"1rem!important"}}> <DoneIcon sx={{fontSize:15}}/></div>
                <div>{symptom}</div>
           </div>)}
           {props.noPreference && props.noPreference?.map(productForm =>
           <div key ={productForm} style={style}>
               <div style={{fontSize:"1rem!important"}}> <DoneIcon sx={{fontSize:15}}/></div>
                <div>{productForm}</div>
           </div>)}
           {props.info && props.noPreference && props.noPreference.length === 0 &&(
           <div style={style}>
           <div > <DoneIcon sx={{fontSize:15}}/></div>
            <div>{props.info}</div>
       </div> )}
           </div>
        </AccordionDetails>
    </Accordion>
    <hr />
        </>
    )
}