import { combineReducers } from 'redux';
import productReducer from '../ccf/questions/ProductReducer';
import questionReducer from '../ccf/questions/QuestionSlice.spec';
import { GlobalState } from './store/global-state';
import eeCareReducer from  './../eecare/EE_careSlice'
import vitamins from '../vitamins/VitaminsSilce';


const rootReducer = combineReducers<GlobalState>({
    quest: questionReducer,
    recommendedProducts: productReducer,
    eeCare:eeCareReducer,
    vitamins
});

export default rootReducer;