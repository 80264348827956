import { createSelector } from "reselect";
import { GlobalState } from "./global-state";

// Icon for questionair screen
import EyeIcon from "../../assets/eyeQuestion/eye.svg";
import ContactLensIcon from "../../assets/eyeQuestion/contact_lenses.svg";
import MedicinesIcon from "../../assets/eyeQuestion/medicines.svg";
import HealthIcon from "../../assets/eyeQuestion/healthicons_ear.svg";

import SeniorIcon from "../../assets/eyeQuestion/ageQuestions/senior.svg";
import AdultIcon from "../../assets/eyeQuestion/ageQuestions/adult.svg";
import ChildIcon from "../../assets/eyeQuestion/ageQuestions/child.svg";
import InfantIcon from "../../assets/eyeQuestion/ageQuestions/infant.svg";
import ToddlerIcon from "../../assets/eyeQuestion/ageQuestions/toddler.svg";

import AlleryIcon from "../../assets/eyeQuestion/questionId3/allergy.svg";
import EyeWashIcon from "../../assets/eyeQuestion/questionId3/eyeWash.svg";
import EyeRednessIcon from "../../assets/eyeQuestion/questionId3/allergy.svg";
import EyeLubricantIcon from "../../assets/eyeQuestion/questionId3/eyeLubricant.svg";
import BlueLightIcon from "../../assets/eyeQuestion/questionId3/blueLight.svg";
import EyeLidIcon from "../../assets/eyeQuestion/questionId3/eyelid.svg";
import WarmCompressIcon from "../../assets/eyeQuestion/questionId3/warmCompress.svg";
import StyeIcon from "../../assets/eyeQuestion/questionId3/Stye.svg";
import HomeopathicIcon from "../../assets/eyeQuestion/questionId3/homeopathic.svg";

import ExtraStrengthIcon from "../../assets/eyeQuestion/allergyEyeCare/strong.svg";
import RegularIcon from "../../assets/eyeQuestion/allergyEyeCare/regular.svg";

import AredIcon from "../../assets/eyeQuestion/eyeVitamin/Ared.svg";
import BlueLitIcon from "../../assets/eyeQuestion/eyeVitamin/BlueLit.svg";
import GeneralEyeIcon from "../../assets/eyeQuestion/eyeVitamin/GeneralEye.svg";

import DeafIcon from "../../assets/earQuestion/deaf.svg";
import EarDeviceIcon from "../../assets/earQuestion/EarDevice.svg";
import EarDropIcon from "../../assets/earQuestion/earDrop.svg";
import OralEarSupplementIcon from "../../assets/earQuestion/oralEarSupplement.svg";

import EightHourIcon from "../../assets/duration/8hour.svg";
import TwelveHourIcon from "../../assets/duration/12hour.svg";
import EighteenHourIcon from "../../assets/duration/18hour.svg";

import HardLensIcon from "../../assets/lens/hardLens.svg";
import SoftLensIcon from "../../assets/lens/softLens.svg";

import Drops from "../../assets/earQuestion/drops.svg";
import NoPreference from "../../assets/earQuestion/pill.svg";
import Wipes from "../../assets/earQuestion/wipes.svg";
import Spray from "../../assets/earQuestion/spray.svg";

import BiWeeklyIcon from "../../assets/eyeQuestion/extra/biWeekly.svg";
import DailyIcon from "../../assets/eyeQuestion/extra/daily.svg";
import MonthlyIcon from "../../assets/eyeQuestion/extra/monthly.svg";
import MultiPurposeIcon from "../../assets/eyeQuestion/extra/MultiPurpose.svg";
import PoisonIcon from "../../assets/eyeQuestion/extra/poison.svg";
import WeeklyIcon from "../../assets/eyeQuestion/extra/weekly.svg";

import DentalIcon from "../../assets/eyeQuestion/extra/dental.svg";
import GogglesIcon from "../../assets/eyeQuestion/extra/goggles.svg";
import NonChemicalIcon from "../../assets/eyeQuestion/extra/nonChemical.svg";
// import Drops from "../../assets/earQuestion/drops.svg"

import { isEmpty } from "../../ccf/Utils/helper";

// Icon for questionair screen

const questionState = (state: GlobalState) => {
  return state.quest;
};

const productState = (state: GlobalState) => {
  return state.recommendedProducts;
};

const selectCurrentQuestionId = createSelector(
  [questionState],
  (state) => state.currentQuestionID
);

const selectQuestions = createSelector(
  [questionState],
  // here its loosing state for questions
  (state) => state.questions
);

export const selectedAnswers = createSelector(
  [questionState],
  (state) => state.answerState
);

export const getAllQuestions = createSelector(
  [selectQuestions],
  (questions) => {
    return questions;
  }
);

export const getAllRecommendedProducts = createSelector(
  [productState],
  (recommendedProducts) => {
    return recommendedProducts;
  }
);

export const getCurrentQuestion = createSelector(
  [selectQuestions, selectCurrentQuestionId],
  (questions, currentQuestionId) => questions[currentQuestionId]
);

// TODO::For products from redux
// export const getSelectedProduct = createSelector(
//   [productState, selectedProductSku],
//   (recommendedProducts, currentQuestionId) => questions[currentQuestionId]
// );

export const selectGetQuestions = createSelector(
  [selectQuestions, selectCurrentQuestionId],
  (questions, currentQuestionId) => {
    const questionIds = Object.keys(questions);
    return questionIds
      .filter((id) => Number(id) >= currentQuestionId)
      .map((id) => {
        return questions[Number(id)];
      });
  }
);

export const selectGetTotalOfQuestions = createSelector(
  [selectQuestions],
  (questions) => Object.keys(questions).length
);

const eyeCareState = (state: any) => {
  return state;
};
export const manipulatedQuestionArray = createSelector(
  eyeCareState,
  (eyeCareState) => {
    if (!isEmpty(eyeCareState)) {
      const option = eyeCareState?.option?.map((item: any) => {
        if (item?.id === 1) {
          return { ...item, icon: EyeIcon };
        } else if (item?.id === 2) {
          return { ...item, icon: ContactLensIcon };
        } else if (item?.id === 3) {
          return { ...item, icon: MedicinesIcon };
        } else if (item?.id === 4) {
          return { ...item, icon: HealthIcon };
        } else if (item?.id === 5) {
          return { ...item, icon: SeniorIcon };
        } else if (item?.id === 6) {
          return { ...item, icon: AdultIcon };
        } else if (item?.id === 7) {
          return { ...item, icon: ChildIcon };
        } else if (item?.id === 8) {
          return { ...item, icon: ToddlerIcon };
        } else if (item?.id === 9) {
          return { ...item, icon: InfantIcon };
        } else if (item?.id === 10) {
          return { ...item, icon: AlleryIcon };
        } else if (item?.id === 11) {
          return { ...item, icon: EyeWashIcon };
        } else if (item?.id === 12) {
          return { ...item, icon: EyeRednessIcon };
        } else if (item?.id === 13) {
          return { ...item, icon: EyeLubricantIcon };
        } else if (item?.id === 14) {
          return { ...item, icon: BlueLightIcon };
        } else if (item?.id === 15) {
          return { ...item, icon: EyeLidIcon };
        } else if (item?.id === 16) {
          return { ...item, icon: WarmCompressIcon };
        } else if (item?.id === 17) {
          return { ...item, icon: StyeIcon };
        } else if (item?.id === 18) {
          return { ...item, icon: ContactLensIcon };
        } else if (item?.id === 19) {
          return { ...item, icon: HomeopathicIcon };
        } else if (item?.id === 20) {
          return { ...item, icon: ExtraStrengthIcon };
        } else if (item?.id === 21) {
          return { ...item, icon: RegularIcon };
        } else if (item?.id === 22) {
          return { ...item, icon: ContactLensIcon };
        } else if (item?.id === 23) {
          return { ...item, icon: NonChemicalIcon };
        } else if (item?.id === 24) {
          return { ...item, icon: NoPreference };
        } else if (item?.id === 25) {
          return { ...item, icon: DentalIcon };
        } else if (item?.id === 26) {
          return { ...item, icon: GogglesIcon };
        } else if (item?.id === 27) {
          return { ...item, icon: NoPreference };
        } else if (item?.id === 28) {
          return { ...item, icon: HardLensIcon };
        } else if (item?.id === 29) {
          return { ...item, icon: SoftLensIcon };
        } else if (item?.id === 30) {
          return { ...item, icon: EightHourIcon };
        } else if (item?.id === 31) {
          return { ...item, icon: TwelveHourIcon };
        } else if (item?.id === 32) {
          return { ...item, icon: EighteenHourIcon };
        } else if (item?.id === 33) {
          return { ...item, icon: DailyIcon };
        } else if (item?.id === 34) {
          return { ...item, icon: WeeklyIcon };
        } else if (item?.id === 35) {
          return { ...item, icon: BiWeeklyIcon };
        } else if (item?.id === 36) {
          return { ...item, icon: MonthlyIcon };
        } else if (item?.id === 37) {
          return { ...item, icon: MultiPurposeIcon };
        } else if (item?.id === 38) {
          return { ...item, icon: PoisonIcon };
        } else if (item?.id === 39) {
          return { ...item, icon: GeneralEyeIcon };
        } else if (item?.id === 40) {
          return { ...item, icon: BlueLitIcon };
        } else if (item?.id === 41) {
          return { ...item, icon: AredIcon };
        } else if (item?.id === 42) {
          return { ...item, icon: DeafIcon };
        } else if (item?.id === 43) {
          return { ...item, icon: EarDropIcon };
        } else if (item?.id === 44) {
          return { ...item, icon: EarDeviceIcon };
        } else if (item?.id === 45) {
          return { ...item, icon: Drops };
        } else if (item?.id === 46) {
          return { ...item, icon: NoPreference };
        } else if (item?.id === 47) {
          return { ...item, icon: Wipes };
        } else if (item?.id === 48) {
          return { ...item, icon: NoPreference };
        } else if (item?.id === 49) {
          return { ...item, icon: Spray };
        } else if (item?.id === 50) {
          return { ...item, icon: OralEarSupplementIcon };
        }
        return item;
      });
      return { ...eyeCareState, option };
    }
    return eyeCareState;
  }
);
