import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./home.css";
import AppHeader from "../../common/component/Header/header";
import "../../common/component/Options/back.css";
import { ROUTES } from "../../common/Routing/Routing";
import mixpanel from "mixpanel-browser";
import {
  reset,
  saveUserChoice,
  setExperience,
  setLogIn,
  setQuestion,
  setReqOptionId,
  setReqQuestionId,
  setTrackUserChoice,
} from "../../eecare/EE_careSlice";
import { useDispatch } from "react-redux";
import store from "../../app/store";
import { useVariable } from "@devcycle/devcycle-react-sdk";
import { eecFirstQuestion } from "../../eecare/EE_Consts";
import QuizComponent from "./quizComponent";

import CCF from "../../assets/SymptomIcon/CCFIcon/cough.svg";
import Eye from "../../assets/Home/eye.svg";
import Ear from "../../assets/Home/ear.svg";
import BannerHomeScreen from "./BannerHomeScreen";
import { getNextQuestion } from "../../API/api";
import { isEmpty } from "../Utils/helper";
// import BannerBgImage from "../../assets/Home/banner.jpeg";
import BannerBgImageNew from '../../assets/Home/bannernew.png'
import CircularProgress from "@mui/material/CircularProgress";
import BackdropUnstyled from "@mui/base/BackdropUnstyled";
const Home: React.FC = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false)
  // Dev Cycle experiment
  const variableKey = "eye-ear-care";
  const FeatureVariable = useVariable(variableKey, false);
  let experience = FeatureVariable.value ? 1 : 0;

  let navigate = useNavigate();
  let isMounted = true;

  let ee_state = store.getState().eeCare;

  // Configure Eye & Ear care experience
  useEffect(() => {
    mixpanel.track("cvs_landing_page");
    if (isMounted) {
      window.onpopstate = (event) => {
        if (event.state.idx > 0) {
          navigate(ROUTES.ROOT);
        }
      };
    }
    if (Object.keys(ee_state.question).length !== 0) {
      dispatch(reset());
      ee_state = store.getState().eeCare;
    }

    return () => {
      isMounted = false;
    };
  }, []);

  // Navigate to cold cough experience
  const startQuestions = async () => {
    mixpanel.track("ccf_first_question", { type: "ccf" });
    // navigate to 1st question
    navigate(ROUTES.QUESTIONS);
  };
  const areas = [
    {
      id: 0,
      icon: CCF,
      label: "Cold, Cough & Flu",
    },
    {
      id: 1,
      icon: Eye,
      label: "Eye care",
    },
    {
      id: 2,
      icon: Ear,
      label: "Ear care",
    },
  ];

  function handleClick(e: any) {
    if (e.label === "Vitamins") {
      navigate(ROUTES.VITAMINS);
      return;
    }
    if (e.label === "Ear care") {
       setLoading(true);
      dispatch(setQuestion(eecFirstQuestion));
      dispatch(setExperience(experience));
      dispatch(setLogIn(true));

      fetchEarData({
        id: 4,
        option: "Ear Care",
        questionId: 1,
        subOption: "Ear Care",
      });
    }
    if (e.label === "Eye care") {
      mixpanel.track("eec_clicked", { type: "eec" });
      mixpanel.track("category_selection ", { type: "eec" });
      dispatch(setQuestion(eecFirstQuestion));
      dispatch(setExperience(experience));
      dispatch(setLogIn(true));
      mixpanel.track("eec_first_question");
      navigate(ROUTES.EEQUESTIONS);
    }
    if (e.label === "Cold, Cough & Flu") {
      dispatch(setExperience(experience));
      mixpanel.track("ccf_clicked", {
        type: "ccf",
      });
      mixpanel.track("category_selection ", { type: "ccf" });
      startQuestions();
      return;
    }
  }

  const fetchEarData = (item: any) => {
    dispatch(saveUserChoice(parseInt(item.id)));
    dispatch(setReqQuestionId(eecFirstQuestion.que[0].id));
    dispatch(setReqOptionId(parseInt(item.id)));

    const reqQue = { age: null, optionId: 1, queId: 1 };
    dispatch(setTrackUserChoice());
    let mixPanelAnswerCountEEC = store.getState().eeCare.trackUserChoice;
    let mixPanellevelCountEEC = store.getState().eeCare.previousReq;
    console.log("eec track", mixPanelAnswerCountEEC, mixPanellevelCountEEC);
    // mix panel
    mixpanel.track("question", {
      type: "eec",
      level: mixPanellevelCountEEC.length,
      answer: mixPanelAnswerCountEEC.length,
      text: item.option,
    });
   
    getNextQuestion(reqQue)
      .then((res) => {
        let data = res;
        if (!isEmpty(data)) {
          dispatch(setQuestion(data));
          navigate(ROUTES.EEQUESTIONS);
        }
      })
      .catch((error) => {
        if (error.toJSON().message === "Network Error") {
          navigate(ROUTES.REQUESTFAILED);
        }
        navigate(ROUTES.REQUESTFAILED);
      }).finally(()=>setLoading(false))
  };
  return (
    <>
    {loading && (
        <BackdropUnstyled
          style={{
            display: "flex",
            height: "100vh",
            justifyContent: "center",
            background: "#ffffff",
            position: "absolute",
            zIndex: 500,
            width: "100%",
          }}
        >
          <CircularProgress
            sx={{ color: "#CC0000", alignSelf: "center", zIndex: "1" }}
          />
        </BackdropUnstyled>
      )}
      {!!experience && (
        <AppHeader
          loadQuestion={null}
          previousIdQuestion={null}
          isBackAllow={false}
        />
      )}
      {experience ? (
        <QuizComponent
          image={BannerBgImageNew}
          handleClick={(data:any)=>handleClick(data)}
          areas={areas}
        />
      ) : (
        <BannerHomeScreen handleClick={handleClick} areas={areas} />
      )}
    </>
  );
};

export default Home;
