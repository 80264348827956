import { Box, Grid } from "@mui/material";
import React, { FunctionComponent, useEffect, useRef, useState } from "react";
import ChooseOne from "../../common/component/Options/Options";
import "./Multiselect.css";
// Types
import {
  LoadQuestionPayload,
  ProductState,
  Question,
  SaverAnswerPayload,
  LoadRcommendedProductPayload,
  answerState,
} from "../questions/Types";
import OptionInfo from "../../common/component/Options/OptionInfo";
import { useNavigate } from "react-router-dom";
import MultiSelect from "../../common/component/Options/MultiSelect";
import { ROUTES } from "../../common/Routing/Routing";
import { MULTI_SELECT_LABEL, SINGLE_SELECT_LABEL } from "../Utils/Constants";
import mixpanel from "mixpanel-browser";
import { getProductRecommendations } from "../../API/api";
import CircularProgress from "@mui/material/CircularProgress";
import BackdropUnstyled from "@mui/base/BackdropUnstyled";
import productImage from "../../assets/product.svg";
import { Modal } from "@mui/material";
import Disclaimer from "../../common/component/Disclaimer/Disclaimer";
import FooterLogo from "../../common/component/BottomLogo";
import { useSelector } from "react-redux";
import { GlobalState } from "../../app/store/global-state";
type QuestionProps = {
  question: Question;
  questions: Question[];
  loadQuestion: (payload: LoadQuestionPayload) => void;
  saveAnswer(payload: SaverAnswerPayload): void;
  allProducts: ProductState;
  saveAllProducts(payload: LoadRcommendedProductPayload): any;
  answerState: answerState;
  scrollToTop?:any
};

const QuestionCard: FunctionComponent<QuestionProps> = ({
  question,
  questions,
  loadQuestion,
  saveAnswer,
  allProducts,
  saveAllProducts,
  answerState,
  scrollToTop
}) => {
  let navigate = useNavigate();
  const [answeredId, setValue] = React.useState<string[]>([]);
  const [symptoms, setSelectedSymptoms] = React.useState<string[]>([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const [symptomsId, setSelectedSymptomsId] = React.useState<number[]>([]);
  const experience = useSelector((state:GlobalState) => state?.eeCare?.userChoice?.user_experiment)
  const [selectAll, setSelectAll] = useState(false);
  // const [open, setOpen] = React.useState(false);
  // const handleOpen = () => setOpen(true);

  

  const handleClose = (qId: number) => {
    mixpanel.track("ccf-disclaimer-screen", {
      type: "ccf",
      experience: localStorage.getItem("experience"),
    });
    // setOpen(false);
    if (localStorage.getItem("experience") === "0") {
      loadQuestion({ questionId: qId + 1 });
    }
    if (localStorage.getItem("experience") === "1") {
      navigate(ROUTES.PREFERENCES, { state: false });
    }
  };

  let isSubscribed = useRef(false);

  const fetchAvailableProducts = async () => {
    if (question.questionId >= 1) {
      // if current question is after AGE AND SYMPTOM'S question
      // get all products for that answers

      const finalRequest = answerState;

      let allFinalProduct: any;
      await getProductRecommendations(finalRequest, false).then((data) => {
        if (data && Object.keys(data).length !== 0) {
          allFinalProduct = data;
        } else {
          navigate(ROUTES.REQUESTFAILED);
        }
      });
      // call reducer action
      saveAllProducts(allFinalProduct);
    }
  };

  useEffect(() => {
    isSubscribed.current = true;
    mixpanel.track("ccf-question", {
      type: "ccf",
      ccf_question: question.question,
    });
    mixpanel.time_event("question");
    if (question.questionId > 1) {
      fetchAvailableProducts();
    }
    return () => {
      isSubscribed.current = false;
    };
  }, [question.questionId]);

  const handleRadioChange = async (answerId: number, value: string) => {
    scrollToTop()
    let answerArray: any[] = [];
    if (value === "Children ages 6+") {
      answerArray.push("Child ages 6 and older".toUpperCase());
    }
    if (value === "Children ages 4+") {
      answerArray.push("Child ages 4 and older".toUpperCase());
    }
    if (value === "Children ages 2+") {
      navigate(ROUTES.CHILDCARE, {
        state: {
          title: "Please consult your child’s healthcare provider for advice.",
          description: `This app is not intended and should not be used to identify products
      for use in children under age 4. Please consult your child's
      healthcare provider for advice.`,
          buttonText: "Try Again",
          route: `${ROUTES.QUESTIONS}`,
        },
      });
    }
    if (value === "Infant or Baby up to 24 months old") {
      // if on 1st question and answer selected is 'Infant or Baby up to 24 months old' navigate to childcare
      navigate(ROUTES.CHILDCARE, {
        state: {
          title: "Please consult your child’s healthcare provider for advice.",
          description: `This app is not intended and should not be used to identify products
        for use in children under age 4. Please consult your child's
        healthcare provider for advice.`,
          buttonText: "Try Again",
          route: `${ROUTES.QUESTIONS}`,
        },
      });
    } else {
      // answerArray = [value.replaceAll(" / ", "/").toUpperCase()];
      answerArray.push(value.replaceAll(" / ", "/").toUpperCase());
    }
    let answerIdArray = [answerId];
    setValue(answerArray);
    const currentQuestionID = question.questionId;
    let mixPanelAnswerCount = 0;
    saveAnswer({
      questionId: question.questionId,
      answers: answerArray,
      answerId: answerIdArray,
    });
    setIsLoading(true);
    await fetchAvailableProducts();
    setIsLoading(false);
    // Mixpanel data
    if (currentQuestionID <= 1) {
      mixPanelAnswerCount = Object.keys(answerState).length;
    } else {
      mixPanelAnswerCount = Object.keys(answerState).length - 1;
    }
    mixpanel.track("question", {
      type: "ccf",
      level: question.questionId + 1,
      answer: mixPanelAnswerCount,
      text: answerArray[0],
    });
    console.log(allProducts.otherSymptomsProduct?.length);
    // if (currentQuestionID === 0 && localStorage.getItem("experience")==="0" ||currentQuestionID === 4 && localStorage.getItem("experience")==="1" ) {
    //   handleOpen();
    //   return
    // }
    if (currentQuestionID >= 4) {
      // if on last question navigate to disclaimer
      // navigate(ROUTES.DISCLAIMER);
      navigate(ROUTES.PREFERENCES, { state: false });
    } else {
      loadQuestion({ questionId: currentQuestionID + 1 });
    }
  };
  const handleSelectAll = ()=>{
    let newArray:any = [];
    let newAnswerId:any = [];
    if(selectAll){
      setSelectedSymptoms([]);
      setSelectedSymptomsId([]);
      saveAnswer({
        questionId: question.questionId,
        answers: [],
        answerId:[],
      });
    }else{
      newArray = question.options.map(function (item) {
        if(item.id !== 13){
          newAnswerId.push(item.id)
          return  item.value.replace(" / ", "/")
        }
        return false
      }).filter((item)=>item)
      setSelectedSymptoms(newArray);
      setSelectedSymptomsId(newAnswerId);
      saveAnswer({
        questionId: question.questionId,
        answers: newArray,
        answerId: newAnswerId,
      });
      mixpanel.track("select_all_checbox_clicked")
    }
    setSelectAll(!selectAll)
    
  }
  
  // useEffect(() => {
   
  //   let newArray:any = question.options.map(function (item) {
  //     if(question?.selectedId?.includes(item?.id)){
  //       return  item.value.replace(" / ", "/")
  //     }
  //     return false
  //   }).filter((item)=>item)
  //   console.log("@@@@ new Array",newArray)
  //  setSelectedSymptoms(newArray)
  //  setSelectedSymptomsId(question?.selectedId||[])
  //  saveAnswer({
  //   questionId: question.questionId,
  //   answers: newArray,
  //   answerId: question?.selectedId,
  // });
  // }, [])
  
  const handleMultiselectChange = async (
    answerId: number,
    value: string,
    isAdding: boolean
  ) => {
    let newArray = [...symptoms];
    let newAnswerId = [...symptomsId];
    // check if answer is selected or deselected
    if (isAdding) {
      if (answerId === 13) {
        setIsLoading(true);
        // check if user selected Cold & Flu multi-symptom solution
        mixpanel.track("multiselect_cold_and_flu", {
          type: "ccf",
          level: question.questionId + 1,
        });
        saveAnswer({
          questionId: question.questionId,
          answers: [value.replaceAll(" / ", "/")],
          answerId: [answerId],
        });

        await fetchAvailableProducts();
        loadQuestion({ questionId: question.questionId + 1 });
        setIsLoading(false);
        return;
      }

      newArray = [...symptoms, value.replace(" / ", "/")];
      newAnswerId = [...symptomsId, answerId];
    } else {
      newArray = newArray.filter(function (item) {
        return item !== value.replaceAll(" / ", "/");
      });
      newAnswerId = newAnswerId.filter(function (item) {
        return item !== answerId;
      });
    }
    setSelectedSymptoms(newArray);
    setSelectedSymptomsId(newAnswerId);
    saveAnswer({
      questionId: question.questionId,
      answers: newArray,
      answerId: newAnswerId,
    });
    setSelectAll(false)
  };

  let childOptions = [];
  if (question.questionId === 0) {
    for (let i = 1; i <= 3; i++) {
      let option = (
        <ChooseOne
          key={question.options[i].id}
          value={question.options[i].id}
          handleClick={handleRadioChange}
          label={question.options[i].value}
          currentQuestionID={question.questionId}
          products={allProducts.otherSymptomsProduct}
          selectId={question.selectedId}
        />
      );
      childOptions.push(option);
    }
  }
  useEffect(() => {
    if(question && question?.selectedId && question?.selectedId?.length > 6){
      setSelectAll(true)
    }else{
      setSelectAll(false)
    }
  }, [question?.selectedId])

  useEffect(() => {
    if(question.isMultiselectAllow && !experience){
      mixpanel.track("ccf_select_all_checkbox_option_screen")
    }else if(question.isMultiselectAllow){
      mixpanel.track("multiselect_cold_and_flu_option_screen")
    }
  }, [question.questionId])
  
  
  return (
    <>
      {isLoading && (
        <BackdropUnstyled
          style={{
            display: "flex",
            height: "100vh",
            overflow: "scroll",
            justifyContent: "center",
            background: "#ffffff",
            position: "absolute",
            top:0,
            left:0,
            width: "100vw",
            zIndex: 500,
          }}
        >
          <CircularProgress
            sx={{ color: "#CC0000", alignSelf: "center", zIndex: "1" }}
          />
        </BackdropUnstyled>
      )}
      <div className="ccf-questionnaire-container">
        <Box
          sx={{
            padding: "15px 24px",
            display: "flex",
            flexDirection: "column",
            flexGrow: 1,
          }}
        >
          <>
            <OptionInfo
              title={question.question}
              info={
                question.isMultiselectAllow
                  ? MULTI_SELECT_LABEL
                  : SINGLE_SELECT_LABEL
              }
              showCheckBox={question.isMultiselectAllow && !experience}
              handleChange={handleSelectAll}
              isSelected={selectAll}
              isGrid={question.isMultiselectAllow}
            />
            <Grid container sx={{ marginTop: "12px" }}>
              <Grid className="categoryItemContainer" item xs={12} sm={6}>
                {question.options.map((option) =>
                  question.isMultiselectAllow ? (
                    <MultiSelect
                      optionId={option.id}
                      key={option.id}
                      value={option.id}
                      handleClick={handleMultiselectChange}
                      label={option.value}
                      selectId={question.selectedId}
                      showOption={option.id === 13 && !experience}
                      isSelected={question?.selectedId?.includes(option.id)}
                    />
                  ) : (
                    <>
                      <div className="single-select-item" key={option.id}>
                        <ChooseOne
                          key={option.id}
                          value={option.id}
                          handleClick={handleRadioChange}
                          label={option.value}
                          currentQuestionID={question.questionId}
                          products={allProducts.otherSymptomsProduct}
                          selectId={question.selectedId}
                        />
                      </div>
                    </>
                  )
                )}
              </Grid>
              <Grid item xs={12} sm={6}>
                <Box
                  sx={{
                    width: "100%",
                    height: "100%",
                    display: { xs: "none", sm: "flex" },
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <img src={productImage} alt="image" width="30%" />
                </Box>
              </Grid>
            </Grid>
          </>
        </Box>
       <Box sx={{marginBottom:question.isMultiselectAllow? "50px": 0}}><FooterLogo /></Box> 
      </div>
      {/* Disclaimer modal */}
      {/* <Modal
        open={open}
        className="custom-modal"
        // aria-labelledby="modal-modal-title"
        // aria-describedby="modal-modal-description"
      >
        <div>
          <Disclaimer handleClick={() => handleClose(question.questionId)} />
        </div>
      </Modal> */}
    </>
  );
};

export default QuestionCard;
