export const questions = [
  {
    id: 0,
    question: "How old is the person you are shopping for?",
    instruction: "Please select one",
    options: [
      { id: 1, value: "Adult ages 19+ " ,nextQuestion:1 },
      { id: 2, value: "Adult ages 18+ " ,nextQuestion:1 },
      { id: 3, value: "Children ages 16+" ,nextQuestion:1},
      { id: 4, value: "Children ages 14+" ,nextQuestion:1},
      { id: 5, value: "Children ages 12+" ,nextQuestion:1},
      { id: 6, value: "Children ages 4+" ,nextQuestion:1},
    ],
    isMultiSelect: false,
    previousQuestion:-1

  },
  {
    id: 1,
    question: "What is your area of concern?",
    instruction: "Please select one",
    options: [
      { id: 7, value: "Sleepless night or Stress" ,nextQuestion:2 },
      { id: 8, value: "Immunity" ,nextQuestion:7},
    ],
    isMultiSelect: false,
    previousQuestion:0

  },
  {
    id: 2,
    question: "Which of these following impact you more?",
    instruction: "Please select one",
    options: [
      { id: 9, value: "Disrupted sleep",nextQuestion:3 },
      { id: 10, value: "Stress",nextQuestion:3 },
      { id: 11, value: "Both",nextQuestion:3 },
    ],
    isMultiSelect: false,
    previousQuestion:1
  },
  {
    id: 3,
    question: "How often does this affect you?",
    instruction: "Please select one",
    options: [
      { id: 12, value: "Everynight",nextQuestion:4 },
      { id: 13, value: "Sometimes",nextQuestion:4 },
    ],
    isMultiSelect: false,
    previousQuestion:2
  },
  {
    id: 4,
    question: "Which of these apply to you?",
    instruction: "Select top 3 reasons",
    options: [
      { id: 14, value: "Lack of focus" },
      { id: 15, value: "Irritable" },
      { id: 16, value: "Lack of energy" },
      { id: 17, value: "Cannot shut off my racing mind" },
      { id: 18, value: "Occasional anxiousness" },
      { id: 19, value: "Can’t fall asleep initially" },
      { id: 20, value: "Wake in the middle of the night / Can’t fall back to sleep " },
      { id: 21, value: "Physical discomfort" },
      { id: 22, value: "Night shift work" },
      { id: 23, value: "Jet lag / Travel" },
      { id: 24, value: "Can’t stay asleep" },
      { id: 25, value: "Need for calm" },
      { id: 26, value: "Spinning thoughts" },
      { id: 27, value: "Fatigue / Lack of Energy" },
    ],
    isMultiSelect: true,
    nextQuestion:5,
    previousQuestion:3
  },
  {
    id: 5,
    question: "What type of product strength you are looking for?",
    instruction: "Please select one",
    options: [
      { id: 28, value: "Regular Strength",nextQuestion:6 },
      { id: 29, value: "Extra  Strength",nextQuestion:6 },
      { id: 30, value: "Max Strength ",nextQuestion:6 },
      { id: 31, value: "No Preference",nextQuestion:6 },
    ],
    isMultiSelect: false,
    previousQuestion:4
  },
  {
    id: 6,
    question: "What is your product form preference ?",
    instruction: "Please select one",
    options: [
      { id: 32, value: "Gummy" ,nextQuestion:-1 },
      { id: 33, value: "Tablet" ,nextQuestion:-1},
      { id: 34, value: "Capsule",nextQuestion:-1 },
      { id: 35, value: "Powder / Effervescent",nextQuestion:-1 },
      { id: 36, value: "Chewable / Fast results",nextQuestion:-1 },
      { id: 37, value: "No Preference",nextQuestion:-1 },
    ],
    isMultiSelect: false,
    previousQuestion:5
  },
  {
    id: 7,
    question: "Are you concerned about your immune health?",
    instruction: "Please select one",
    options: [
      { id: 38, value: "Yes, daily",nextQuestion:8 },
      { id: 39, value: "Only on occasion",nextQuestion:8 },
    ],
    isMultiSelect: false,
    previousQuestion:0
  },
  {
    id: 8,
    question: "Are you concerned about your immune health?",
    instruction: "Select top 3 reasons",
    options: [
      { id: 40, value: "I’d like to support my everyday immune health"},
      { id: 41, value: "I get sick easily" },
      { id: 42, value: "I feel rundown" },
      { id: 43, value: "I will be traveling by airplane or by public transportation soon." },
      { id: 44, value: "I work/live around pollution in my environment." },
      { id: 45, value: "I am frequently exposed to large groups of people at work" },
      { id: 46, value: "I feel occasionally congested or have a runny nose." },
    ],
    isMultiSelect: true,
    nextQuestion:5,
    previousQuestion:7
  },
];
