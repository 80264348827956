import { useEffect, useRef } from "react";
import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
import Home from "../../ccf/home/home";
import Questionniar from "../../ccf/questions/Questionniar";
import Disclaimer from "../component/Disclaimer/Disclaimer";
import ChildcareLanding from "../component/Childcare/ChildCareLanding";
import ProductPreference from "../component/ProductPreference/ProductPreference";
import { ROUTES } from "./Routing";
import ProductDetail from "../component/ProductDetail/Productdetail";
import ProductComboPreferences from "../component/ProductPreference/ProductComboPreferences";
import Vitamins from "../../vitamins/Vitamins";
import FeverDisclaimer from "../component/Disclaimer/FeverDisclaimer";
import ReusableDisclaimer from "../component/Disclaimer/ReusableDisclaimer";
import EEQuestionCard from "../../eecare/EEQuestionCard";
import PageNotFound from "../component/Error/PageNotFound";
import RequestError from "../component/Error/RequestError";
import { useDispatch, useSelector } from "react-redux";
import { GlobalState } from "../../app/store/global-state";
import VitaminsPreference from "../../vitamins/VitaminsPreference";
import EEProductOutcome from "../../eecare/EEProductOutcome";
import { setUserDropTime } from "../../eecare/EE_careSlice";
import mixpanel from "mixpanel-browser";

export default function AppRouter() {
  const ee_state = useSelector((state: GlobalState) => state.eeCare);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userLeftTime = useRef(ee_state?.userDroppedAt);
  userLeftTime.current = ee_state?.userDroppedAt;

  const checkTimeDiffrence = () => {
    const userTime = userLeftTime.current;
    if (userLeftTime) {
      const currentTime = new Date();
      var difference = currentTime.getTime() - userTime.getTime();
      var minutesDifference = Math.floor(difference / 1000 / 60);
      return minutesDifference;
    } else {
      return 0;
    }
  };

  useEffect(() => {
    function onVisibilityChange() {
      if (document.visibilityState === "visible") {
        if (!userLeftTime.current) {
        } else if (userLeftTime.current && (checkTimeDiffrence() > 30)) {
          // if user was inactive for more than 30 minutes and came back 
          mixpanel.track("session_expired_user_inactive")
          navigate(ROUTES.ROOT);
        } else {
          // if user was inactive and came back before 30 minutes
          dispatch(setUserDropTime(null));
        }
      } else {
        const date = new Date();
        dispatch(setUserDropTime(date));
      }
    }
    document.addEventListener("visibilitychange", (e) => onVisibilityChange());
  }, []);

  return (
    <Routes>
      <Route path={ROUTES.ROOT} element={<Home />} />
      <Route path={ROUTES.EAR} element={<Home />} />
      <Route path={ROUTES.CCF} element={<Home />} />
      <Route path={ROUTES.QUESTIONS} element={<Questionniar />} />
      <Route
        path={ROUTES.CHILDCARE}
        element={
          <ChildcareLanding title="" description="" buttonText="" route="" />
        }
      />
      <Route path={ROUTES.DISCLAIMER} element={<Disclaimer />} />
      <Route path={ROUTES.PREFERENCES} element={<ProductPreference />} />
      <Route
        path={ROUTES.COMBOPREFERENCESNAVIGATION}
        element={<ProductComboPreferences />}
      />
      <Route path={ROUTES.FIRSTAID} element={<Home />}></Route>
      <Route path={ROUTES.VITAMINS} element={<Vitamins />}></Route>
      <Route
        path={ROUTES.VITPREFERENCE}
        element={<VitaminsPreference />}
      ></Route>
      <Route
        path={ROUTES.FEVERDISCLAIMER}
        element={<FeverDisclaimer title="" />}
      ></Route>
      <Route
        path={ROUTES.REUSABLEDISCLAIMER}
        element={
          <ReusableDisclaimer
            description="Disclaimer"
            buttonText="Continue"
            route=""
            cancel=""
          />
        }
      ></Route>

      <Route
        path={ROUTES.EEQUESTIONS}
        element={
          ee_state.loggedIn ? <EEQuestionCard /> : <Navigate to={ROUTES.ROOT} />
        }
      ></Route>
      <Route path={ROUTES.EEOUTCOME} element={<EEProductOutcome />}></Route>
      <Route path={ROUTES.REQUESTFAILED} element={<RequestError />}></Route>
      <Route path={ROUTES.NOTFOUND} element={<PageNotFound />}></Route>

      <Route
        path={ROUTES.PRODUCTDETAILS}
        element={
          <ProductDetail
            product={{
              status: false,
              comments: undefined,
              ingredient1: "",
              ingredient2: "",
              ingredient3: "",
              ingredient4: "",
              isProductSugarFree: "",
              productAvailability: "",
              productBrand: "",
              productBrandPriority: "",
              productForAge: "",
              productForm: "",
              productPrice: 0,
              productRecommendationType: "",
              productReliefType: "",
              productSku: 0,
              productSkuShortName: "",
              productStrength: "",
              productImageURL: "",
              productWarnings: "",
              productReview: 0,
              productRating: 0,
              productDirection: "",
              productDetails: "",
              productPageUrl: "",
              productFormShortName: "",
              symptom: "",
            }}
          />
        }
      />
    </Routes>
  );
}
