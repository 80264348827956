import { Box, Modal, Stack } from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import AddonCard from "../../common/component/Addoncard/AddonCard";
import AppHeader from "../../common/component/Header/header";
import BackdropUnstyled from "@mui/base/BackdropUnstyled";
import CircularProgress from "@mui/material/CircularProgress";
import SingleProductSlider from "../../common/component/ProductPreference/SingleProductSlider";
import { fetchEyeEarDataApi } from "../../API/api";
import store from "../../app/store";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import mixpanel from "mixpanel-browser";
import { ROUTES } from "../../common/Routing/Routing";
import FooterLogo from "../../common/component/BottomLogo";
import {setFeedbackSubmited, setInitializeEardata, setInitializeEyedata } from "../EE_careSlice";
import { GlobalState } from "../../app/store/global-state";
import { useIdleTimer } from "react-idle-timer";
import FeedbackPopup from "../../common/component/ProductPreference/FeedbackPopup";

const EEProductOutcome = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const [products, setProducts] = useState([]);
  const [addOnProducts, setAddOnProducts] = useState([]);
  const [openFeedbackPopup, setOpenFeedbackPopup] = React.useState("");
  const experience = useSelector(
    (state: GlobalState) => state?.eeCare?.userChoice?.user_experiment
  );
  const feedbackSubmited = useSelector(
    (state: GlobalState) => state?.eeCare?.feedbackSubmited
  );
  const userChoice = useSelector((state: GlobalState) => state?.eeCare?.userChoice)
  useEffect(() => {
    mixpanel.track("eec-preferences-Selected", {
      type: "eec",
      preferences: userChoice,
    });
    fetchProducts();
  }, []);

  // fetch product as per user's choice selected
  const fetchProducts = () => {
    let body = { ...store.getState().eeCare.userChoice };
    fetchEyeEarDataApi(body)
      .then((res: any) => {
        if (res?.EECOutcomeProducts?.length) {
          setProducts(res?.EECOutcomeProducts);
          setAddOnProducts(res?.EECComplementaryProducts || []);
        } else {
          // alert("Somthing went wrong");
        }
      })
      .catch((error) => {
        navigate(ROUTES.REQUESTFAILED);
      })
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    if (products.length) {
      products?.map((item:any, index) => {
        mixpanel.track("recommended-products", {
          type: "eec",
          position: index,
          productName: item?.productSkuShortName,
          productBrand: item?.productBrand,
          productId: item?.productId,
        });
      });
      addOnProducts?.map((item:any, index) => {
        mixpanel.track(`complementry-${index+1}`, {
          type: "eec",
          position: index,
          productName: item?.productSkuShortName,
          productBrand: item?.productBrand,
          productId: item?.productId,
        });
      });
    }
  }, [products?.length]);

  const handleStartOver = () => {
    userChoice?.caretype === 4 ? dispatch(setInitializeEardata()) : dispatch(setInitializeEyedata());
    mixpanel.track("eec_start_over_clicked", { type: "eec" });
    navigate(ROUTES.EEQUESTIONS);
  };

  const startOver = (
    <div>
      <button className="btn-primary-prefence" onClick={handleStartOver}>
        Start Over
      </button>
    </div>
  );

  const onMemo = useMemo(() => {
    return (
      <div>
        {/* <h3 className="productSlider_heading">Cold Sore</h3> */}
        <div>
          {products && products ? (
            <div className="carousel-swipper">
              <SingleProductSlider products={products} />
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    );
  }, [products?.length]);

  //FEEDBACK SECTION
  const onIdle = () => {
    !feedbackSubmited &&
      openFeedbackPopup !== "close" &&
      setOpenFeedbackPopup("open");
  };

  useIdleTimer({
    onIdle,
    timeout: 10_000,
    throttle: 500,
  });

  const onSubmitFeedback = (feedback: string) => {
    mixpanel.track("user-feedback", {
      type: "eec",
      ui_experiment: experience,
      feedback: feedback,
    });
    dispatch(setFeedbackSubmited(true));
    setOpenFeedbackPopup("close");
  };
  //FEEDBACK SECTION
  return (
    <>
      <AppHeader
        loadQuestion={null}
        previousIdQuestion={null}
        isBackAllow={false}
      />
      {/* Loader */}
      {isLoading && (
        <BackdropUnstyled
          style={{
            display: "flex",
            height: "100vh",
            justifyContent: "center",
            background: "#ffffff",
            position: "absolute",
            zIndex: 500,
            width: "100%",
          }}
        >
          <CircularProgress
            sx={{ color: "#CC0000", alignSelf: "center", zIndex: "1" }}
          />
        </BackdropUnstyled>
      )}
      {/* Loader */}

      <Box
        sx={{
          padding: "15px",
          background: "#ffffff",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          flexGrow: 1,
        }}
      >
        <Box>
          <Box
            sx={{
              display: "flex",
              flexWrap: "nowrap",
            }}
          >
            <Box>{startOver}</Box>
          </Box>
          {!products?.length && !isLoading && (
            <h3 className="productSlider_heading">
              Unfortunately we couldn’t find any product that matched your
              preferences
            </h3>
          )}

          {products && products?.length > 0 && (
            <h3 className="productSlider_heading">
              Recommended {products?.length > 1 ? "Products" : "Product"} for
              you
            </h3>
          )}
          <Box>{products && products?.length > 0 ? onMemo : ""}</Box>
        </Box>

        {/* Add ons */}
        {addOnProducts && addOnProducts?.length > 0 && (
          <Box
            sx={{
              width: "100%",
              background: "#ffffff",
              height: "100%",
            }}
          >
            <p className="productSlider_heading mt-1">
              Frequently Bought Together :
            </p>
            <Box sx={{ marginTop: "20px", overflow: "auto" }}>
              <Stack
                flexWrap={{ xs: "wrap", sm: "wrap", md: "wrap" }}
                direction={{ xs: "row", sm: "row", md: "row" }}
                justifyContent={{ xs: "space-between", sm: "flex-start" }}
                gap={2}
              >
                {addOnProducts &&
                  addOnProducts.length > 0 &&
                  addOnProducts.map((value: any) => (
                    <Box
                      sx={{
                        border: "1px solid #E9E9E9",
                        width: { xs: "46%", sm: "164px" },
                      }}
                    >
                      <AddonCard key={value.productSku} product={value} />
                    </Box>
                  ))}
              </Stack>
            </Box>
          </Box>
        )}
        {/* Add ons Ends*/}
        <FooterLogo />
      </Box>

      <Modal
        open={openFeedbackPopup === "open"}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{
          display: "flex",
          flexDirection: "column",
          flexGrow: 1,
          height: "100%",
          padding: "20px 15px",
        }}
      >
        <FeedbackPopup
          setFeedbackPopup={setOpenFeedbackPopup}
          onSubmitFeedback={onSubmitFeedback}
        />
      </Modal>
    </>
  );
};

export default EEProductOutcome;
