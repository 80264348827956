// import { makeStyles } from '@material-ui/core';
import React, { FunctionComponent, useEffect, useState } from "react";
import { Box, Modal } from "@material-ui/core";
import {
  answerState,
  LoadQuestionPayload,
  LoadRcommendedProductPayload,
  ProductState,
} from "../../../ccf/questions/Types";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../Routing/Routing";
import "./CVSFooter.css";
import { selectedAnswers } from "../../../app/store/selectors";
import { GlobalState } from "../../../app/store/global-state";
import { createStructuredSelector } from "reselect";
import { connect } from "react-redux";
import { getProductRecommendations } from "../../../API/api";
import CircularProgress from "@mui/material/CircularProgress";
import BackdropUnstyled from "@mui/base/BackdropUnstyled";
import mixpanel from "mixpanel-browser";
import Disclaimer from "../Disclaimer/Disclaimer";
import store from "../../../app/store";

type FooterProps = {
  loadQuestion: (payload: LoadQuestionPayload) => void;
  nextIdQuestion: number | null;
  allProducts: ProductState;
  saveAllProducts(payload: LoadRcommendedProductPayload): any;
  activeButton: Boolean
  scrollToTop:any
};

type StateProps = {
  answers: answerState;
};

type Props = StateProps & FooterProps;

const CVSFooter: FunctionComponent<Props> = ({
  loadQuestion,
  nextIdQuestion,
  answers,
  allProducts,
  saveAllProducts,
  activeButton,
  scrollToTop
}) => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = React.useState(false);
   const [open, setOpen] = React.useState(false);
  const nextQuestionId = nextIdQuestion ?? -1;
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    mixpanel.track("ccf-disclaimer-screen", { type: "ccf",experience:localStorage.getItem("experience") });
    setOpen(false);
    if(localStorage.getItem("experience")==="1"){
      navigate(ROUTES.PREFERENCES, { state: false });
    }
   
  };
  useEffect(() => {
    if (answers.symptoms && answers?.symptoms?.length > 0) {
      mixpanel.time_event('question')
      if (
        nextQuestionId > 5 ||
        !allProducts.otherSymptomsProduct ||
        (allProducts.otherSymptomsProduct &&
          allProducts.otherSymptomsProduct.length === 0)
      ) {
        if (
          answers.symptoms.length === 1 &&
          answers.symptoms.includes("Fever and Pain/Body Aches")
        ) {
          navigate(ROUTES.FEVERDISCLAIMER, {
            state: { title: "Please visit the External Pain Aisle" },
          });
        }
      } else {
        if (
          answers.symptoms.length === 1 &&
          answers.symptoms.includes("Cold Sore")
        ) {
          // if cold sore then skip all next questions   
          navigate(ROUTES.PREFERENCES, { state: false });
        } 
        else if (
          answers.symptoms.length === 1 &&
          answers.symptoms.includes("Fever and Pain/Body Aches")
        ) {
          navigate(ROUTES.FEVERDISCLAIMER, {
            state: { title: "Please visit the External Pain Aisle" },
          });
        }   
      }
    }
    setIsLoading(false);
  }, [allProducts]);
  const navigateToNextQuestion = async (nextQuestionID: number) => {
    if (answers && answers.symptoms && answers.symptoms.length > 0) {
      scrollToTop()
      mixpanel.track("question",{
        type:"ccf",
        level:nextQuestionId + 1,
        answer:Object.keys(answers).length,
        text:answers.symptoms,
      })
      setIsLoading(true);
      await fetchAvailableProducts();
      if (
        answers.symptoms.length === 1 &&
        answers.symptoms.includes("Cold Sore")
      ) {
        // if cold sore then skip all next questions
        if(localStorage.getItem("experience")==="1"){
          handleOpen()
          return
        }
        
        navigate(ROUTES.PREFERENCES, { state: false });
        return
      }
      if(allProducts.otherSymptomsProduct && store.getState().recommendedProducts.otherSymptomsProduct?.length === 0 && allProducts.coldSoreProduct && store.getState().recommendedProducts.coldSoreProduct!.length > 0      
        && localStorage.getItem("experience")==="1"){
          handleOpen()
          return
        }
      setIsLoading(false);
      loadQuestion({ questionId: nextQuestionId + 1 });
    }
  };

  useEffect(() => {}, [isLoading]);

  const fetchAvailableProducts = async () => {
    // get all products for that answers
    const finalRequest = answers;
    let allFinalProduct: any 
    await getProductRecommendations(
      finalRequest,
      false
    ).then(data=>{
      if(data && Object.keys(data).length !== 0){
        allFinalProduct = data
        if(allFinalProduct?.otherSymptomsProduct?.length === 0 && allFinalProduct?.coldSoreProduct?.length ===0){
          handleOpen()
          navigate(ROUTES.PREFERENCES, { state: false });
        }else if((allFinalProduct?.otherSymptomsProduct?.length === 0) && (allFinalProduct?.coldSoreProduct?.length > 0)){
          navigate(ROUTES.PREFERENCES, { state: false });
        }else if((allFinalProduct?.otherSymptomsProduct?.length === 0) && (allFinalProduct?.soreThroatProduct?.length > 0)){
          navigate(ROUTES.PREFERENCES, { state: false });
        }
      }
      else{navigate(ROUTES.REQUESTFAILED)}
    });
    
    // call reducer action
    saveAllProducts(allFinalProduct);
  };

  return (
    <>
      {isLoading && (
        <BackdropUnstyled
        style={{
          position: "absolute",
          top: 0,
          background: "white",
          height: "100%",
          width: "100%",
          maxWidth:"1366px",
          display: "flex",
          justifyContent: "center"
        }}
        >
          <CircularProgress
            sx={{ color: "#CC0000", alignSelf: "center", zIndex: "1" }}
          />
        </BackdropUnstyled>
      )}

      <Box
      className="fixedToBottom"
        sx={{
          width: "100%",
          
        }}
        
      >      
        <button
          className="bottomButton"
          onClick={() =>
            navigateToNextQuestion(nextIdQuestion ? nextIdQuestion : 0)
          }
          disabled={!activeButton}
        >
          Next
        </button>
      </Box>
       {/* Disclaimer modal */}
       <Modal
        open={open}
        className="custom-modal"
        // aria-labelledby="modal-modal-title"
        // aria-describedby="modal-modal-description"
      >
        <div>
          <Disclaimer handleClick={() => handleClose()} />
        </div>
      </Modal>
    </>
  );
};

const mapStateToProps = createStructuredSelector<GlobalState, StateProps>({
  answers: selectedAnswers
});

export default connect(mapStateToProps)(CVSFooter);
