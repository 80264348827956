import Box from "@mui/material/Box";
import shopsimple from "../../../assets/shop.svg";
import { useLocation, useNavigate } from "react-router-dom";
import "./childcarelanding.css";
import hero from "./../../../assets/childcare.jpg";
import AppHeader from "../Header/header";
import { ROUTES } from "../../Routing/Routing";
import React from "react";
import mixpanel from "mixpanel-browser";
import { Grid, Stack } from "@mui/material";

type childCareProps = {
  title: string;
  description: string;
  buttonText: string;
  route: string;
  mixPanelEvent?: string;
};

export default function ChildCareLanding(props: childCareProps) {
  let navigate = useNavigate();
  const { state } = useLocation();
  const childCareInfo = state as childCareProps;
  mixpanel.track(`${childCareInfo.mixPanelEvent}`);

  return (
    <>
      <AppHeader
        loadQuestion={null}
        previousIdQuestion={null}
        isBackAllow={false}
      />
      <Box
        sx={{
          height: "100vh",
          background: "#ffffff",
          display: { xs: "block", sm: "block", md: "none" },
        }}
      >
        <Box
          sx={{
            height: "40%",
            width: "100%",
            backgroundImage: `url(${hero})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              paddingTop: "20px",
            }}
          >
            <img src={shopsimple} alt="logo" />
          </Box>
        </Box>
        <Box sx={{ padding: "25px" }}>
          <Stack gap={1.5}>
            <Box>
              <p className="ccf-question-heading">{childCareInfo.title}</p>
            </Box>
            <Box>
              <p className="ccf-info-text text-info">
                {childCareInfo.description}
              </p>
            </Box>
            <Box>
              <button
                className="ccf-btn-primary"
                onClick={() => navigate(childCareInfo.route)}
              >
                {childCareInfo.buttonText}
              </button>
            </Box>
          </Stack>
        </Box>
      </Box>
      {/* landscape */}
      <Box
        sx={{
          height: "100vh",
          background: "#ffffff",
          display: { xs: "none", sm: "none", md: "block" },
        }}
      >
        <Grid container>
          <Grid item md={6}>
            <Box
              sx={{
                backgroundImage: `url(${hero})`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                height: "100vh",
                width: "100%",
                backgroundPosition: "center",
              }}
            ></Box>
          </Grid>
          <Grid item md={6}>
            <Box
              sx={{
                height: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Stack sx={{ width: "75%" }} gap={2.5}>
                <Box>
                  <p className="ccf-question-heading">{childCareInfo.title}</p>
                </Box>
                <Box>
                  <p className="ccf-info-text text-info">
                    {childCareInfo.description}
                  </p>
                </Box>
                <Box>
                  <button
                    className="ccf-btn-primary"
                    onClick={() => navigate(childCareInfo.route)}
                  >
                    {childCareInfo.buttonText}
                  </button>
                </Box>
              </Stack>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}
