import { Box, Checkbox } from "@mui/material";
import "./option.css";
export default function OptionInfo(props: any) {
  return (
    <Box sx={{width:{xs:'100%',sm:'50%'}}}>
        <h1 className="question-heading" style={props?.isGrid ? {fontSize:20}:{}} >{props.title}</h1>
        <Box display="flex" justifyContent="space-between" alignItems="center" className="mt-1" ><Box className="ccf-info-text text-grey" sx={{fontSize:"1rem"}}>{props.info}</Box>
        {props?.showCheckBox ? <Box className="ccf-info-text text-grey" sx={{fontSize:"1rem"}}> Select All <Checkbox
            checked={props?.isSelected}
            onChange={props?.handleChange}
            sx={{
              color: "#BFBFBF",
              padding:0,
              "&.Mui-checked": {
                color: "#C00",
              },
            }}
          /> </Box>: null}
        </Box>
    </Box>
  );
}
