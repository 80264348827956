import { Box, Stack } from "@mui/material";
import AppHeader from "../Header/header";
import pageNotFound from '../../../assets/page_not_found.svg'
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../Routing/Routing";

export default function PageNotFound(){
    let navigate = useNavigate();

    function handleClick(){
        navigate(ROUTES.ROOT)
    }
    return(
        <>
          <AppHeader
        loadQuestion={null}
        previousIdQuestion={null}
        isBackAllow={false}
      />
      <Box sx={{
        width:'100%',
        height:'100vh',
        background:'#ffffff'
      }}>

        <Stack  gap={2.5} justifyContent="center" alignItems='center' sx={{width:'100%',height:'100%'}}>
            <Box sx={{
                fontSize:'2rem',
                color:'#CC0000',
                fontFamily:"HealthCVS"
            }}>
                Page Not Found
            </Box>
            <Box sx={{
                width:'100%',
                display:'flex',
                justifyContent:'center'
            }}>
                <img src={pageNotFound} alt="Page not found" width="50%" />
            </Box>
            <Box>
                <button className="ccf-btn-primary" onClick={handleClick}>Back To Home</button>
            </Box>
        </Stack>
        
      </Box>
        </>
    )
}