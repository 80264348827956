import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import landing_img from "../../../assets/landing_bg.jpg";
import shop from "../../../assets/shopsimpleicon.svg";
import AppHeader from "../Header/header";
import { useLocation, useNavigate } from "react-router-dom";
import { ROUTES } from "../../Routing/Routing";
import { Grid, Stack } from "@mui/material";
import landingPageImage from "../../../assets/homepage_tab.png";
import hompageLandScape from "../../../assets/homepage_landscape.png";
import { width } from "@mui/system";

type feverProps= {
    title:string
}

export default function FeverDisclaimer(props:feverProps){
    let navigate = useNavigate();
    const { state } = useLocation();
    const  title = state as feverProps
    return(
      <>
      <AppHeader
        loadQuestion={null}
        previousIdQuestion={null}
        isBackAllow={false}
      />
       <Box
        sx={{
          height: "100vh",
          background: "#ffffff",
          display: { xs: "block", sm: "block", md: "none" },
        }}
      >
        <Box
          sx={{
            height: "40%",
            width: "100%",
            backgroundImage: `url(${landingPageImage})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
          }}
        >
        </Box>
        <Box sx={{ padding: "25px" ,width:{xs:"100%",sm:"50%"}}}>
          <Stack gap={2.3}>
            <Box>
              <p className="ccf-question-heading">{title.title}</p>
            </Box>
            <Box>
              <button
                className="ccf-btn-primary"
                onClick={() => navigate(ROUTES.QUESTIONS)}
              >
                Try Again
              </button>
            </Box>
            <Box>
            <img src={shop} alt="shop simple" />
            </Box>
          </Stack>
        </Box>
      </Box>
      {/* landscape */}
      <Box
        sx={{
          height: "100vh",
          background: "#ffffff",
          display: { xs: "none", sm: "none", md: "block" },
        }}
      >
        <Grid container>
          <Grid item md={6}>
            <Box
              sx={{
                backgroundImage: `url(${hompageLandScape})`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                height: "100vh",
                width: "100%",
                backgroundPosition: "center",
              }}
            ></Box>
          </Grid>
          <Grid item md={6}>
            <Box
              sx={{
                height: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Stack sx={{ width: "50%" }} gap={2.3}>
                <Box>
                  <p className="ccf-question-heading">{title.title}</p>
                </Box>
                <Box>
                  <button
                    className="ccf-btn-primary"
                    onClick={() => navigate(ROUTES.QUESTIONS)}
                  >
                   Try Again
                  </button>
                </Box>
                <Box>
             <img src={shop} alt="shop simple" />
            </Box>
              </Stack>
            </Box>
          </Grid>
        </Grid>
      </Box>
      </>
    )
}