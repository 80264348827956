import { Box } from "@mui/material";
import BannerBgImage from "../../assets/Home/bannernew2.png";
import FooterLogo from "../../common/component/BottomLogo";
import Logo from "../../assets/Home/logo.svg";
import ShowItem from "./showItem";
import { useEffect } from "react";
import mixpanel from "mixpanel-browser";
interface propsPayload {
  handleClick: any;
  areas: any;
}
const BannerHomeScreen = ({ handleClick, areas }: propsPayload) => {
  useEffect(() => {
    mixpanel.track("cvs_landing_page_banner_screen");
  }, [])
  
  return (
    <Box
      sx={{
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
        overflow: "scroll",
      }}
    >
      <Box sx={{ backgroundColor: "#C00", padding: "16px 24px" }}>
        <img
          src={Logo}
          style={{ width: "120px", height: "18px" }}
          alt="CVS-logo"
        />
      </Box>

      <Box sx={{ display: "flex", flexGrow: 1, flexDirection: "column" }}>
        <Box
          sx={{
            maxHeight: {
              // xs: "17rem",
            },
            minHeight: {
              xs: "26vh",
            },
            width: "100%",
            backgroundImage: `url(${BannerBgImage})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundColor: "rgba(0,0,0,.4)",
            backgroundBlendMode: "multiply",
            aspectRatio: {
              // xs: "1/1",
            },
            display: {
              xs: "flex",
            },
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            overflow: "hidden",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              flex: 1,
              width: "100%",
            }}
          >
            <Box display="flex" justifyContent="center" alignItems="center">
              <Box
                sx={{
                  fontFamily: "HealthCVS",
                  fontSize: "28px",
                  // fontSize:'2.25rem',
                  color: "#ffffff",
                  padding: 2,
                  fontStyle: "normal",
                  fontWeight: 700,
                  lineHeight: "normal",
                  letterSpacing: "-0.72px",
                  width: {
                    xs: "100%",
                    sm: "70%",
                    md: "60%",
                  },
                }}
              >
                Take our 30-second Quiz for Personalized Wellness
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              background: "#FFF",
              filter: "blur(15px)",
              height: "50px",
              width: "140%",
              position: "relative",
              bottom: "-12%",
            }}
          ></Box>
        </Box>

        <Box px="24px" py="16px">
          <Box className="selectText">
            Answer a few questions to get matched with the best product for your
            needs.
          </Box>
          <Box className="selectCategory">
          Select Category:
          </Box>
          <ShowItem data={areas} handleClick={handleClick} />
          <p className="disclaimerText">
            *The experience is for the purpose of education and informing the
            customer. It is not medical advice or to replace an examination by
            physician.
          </p>
        </Box>
      </Box>
      <FooterLogo />
    </Box>
  );
};

export default BannerHomeScreen;
