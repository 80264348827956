
import { AUTH_TOKEN } from "../ccf/Utils/Constants";



export const config = {
  headers: { Authorization: `Bearer ${AUTH_TOKEN}` },
};

export const childCareTitle =
  "Please consult your child’s healthcare provider for advice.";

export const childCareDescription =
  "This app is not intended and should not be used to identify products for use in children under age 2. Please consult your child's healthcare provider for advice.";

export const childCareBtn = "Try Again";

export const disclaimerDescription =
  "The information provided here is not intended to diagnose, treat, cure, or prevent any medical condition.  It is intended solely for informational purposes and should not be used as a substitute for consultation with your healthcare provider.  Each person’s health and wellness needs are different and should be individually evaluated by a qualified healthcare provider.   Follow the directions of your healthcare provider with regard to the use of your specific medications and treatment of your medical conditions.";

export const disclaimerBtn = "Continue";

export const eecFirstQuestion = {
  isFinal: false,
      que: [
          {
              id: 1,
              question: "What type of product are you looking for?"
          }
      ],
      option: [
          {
              id: 1,
              option: "Eye Care",
              questionId: 1,
              subOption: "Eye Care"
          },
          {
              id: 2,
              option: "Contact Lens Care",
              questionId: 1,
              subOption: "Contact Lens Care"
          },
          {
              id: 3,
              option: "Oral Eye Supplements",
              questionId: 1,
              subOption: "Oral Eye Supplements"
          },
          {
              id: 4,
              option: "Ear Care",
              questionId: 1,
              subOption: "Ear Care"
          }
      ]
}