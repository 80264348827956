import { Box, Stack } from "@mui/material";
import AppHeader from "../Header/header";
import pageNotFound from "../../../assets/page_not_found.svg";
import { useLocation, useNavigate } from "react-router-dom";
import { ROUTES } from "../../Routing/Routing";
import Carousel from "react-elastic-carousel";

export default function RequestError() {
  let navigate = useNavigate();

  function handleClick() {
    navigate(ROUTES.ROOT);
  }
  return (
    <>
      <AppHeader
        loadQuestion={null}
        previousIdQuestion={null}
        isBackAllow={false}
      />
      <Box
        sx={{
          width: "100%",
          height: "100vh",
          background: "#ffffff",
        }}
      >
        <Carousel
          itemsToShow={1}
          isRTL={false}
          showArrows={false}
          pagination={false}
        >
          <Stack
            gap={5}
            sx={{
              width: "80%",
              background: "rgba(0,0,0,.03)",
              padding: "0.75rem 1.25rem",
              border: "1px solid #eee",
              margin: "5% auto",
            }}
          >
            <Box>
              <p
                style={{
                  fontFamily: "HealthCVS",
                  fontSize: "24px",
                  lineHeight: "1.07",
                }}
              >
                There’s a temporary problem with the service, Please Try Again
                or visit{" "}
                <a href="http://cvs.com/" style={{ color: "#cc0000" }}>
                  cvs.com
                </a>
              </p>
            </Box>
            <Box>
              <button className="cvs-network-error-btn" onClick={handleClick}>
                Go To Home
              </button>
            </Box>
          </Stack>
        </Carousel>
      </Box>
    </>
  );
}
