import axios from "axios";
import { AUTH_TOKEN, BASE_API_URL } from "../ccf/Utils/Constants";
const Axios = axios.create({
  baseURL: BASE_API_URL,
  timeout: 10000,
});

// request interceptor
Axios.interceptors.request.use(
  (config: any) => {
    const token = AUTH_TOKEN
    if (token) {
      config.headers["Authorization"] = "Bearer " + token;
    }
    config.headers["Content-Type"] = "application/json";
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

Axios.interceptors.response.use(
  (response) => {
    return response;
  },
  function (error) {


    // Handle error here 
    // const originalRequest = error.config;

    // not a logged in user
    //   if (
    //     error.response.status === 401 &&
    //     originalRequest.url === 'http://127.0.0.1:3000/v1/auth/token'
    //   ) {
    //     // user not logged in
    //     return Promise.reject(error)
    //   }

    //   if (error.response.status === 401 && !originalRequest._retry) {
    //     originalRequest._retry = true
    //     const refreshToken = null // fetch Token from local storage
    //     return Axios
    //       .post('/auth/token', {
    //         refresh_token: refreshToken
    //       })
    //       .then(res => {
    //         if (res.status === 201) {
    //         //   set Token
    //           return axios(originalRequest)
    //         }
    //       })
    //   }
    return Promise.reject(error);
  }
);

type requestProps = {
  url: string;
  body: any;
};

export const postApiRequest = (props: requestProps) => {
  const { url, body } = props;
 return new Promise((resolve, reject) => {
    return Axios.post(url, body)
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
};

export const getApiRequest = (props: requestProps) => {
    const { url } = props;
   return  new Promise((resolve, reject) => {
      return Axios.get(url)
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  };