import { Provider } from "react-redux";
import { store } from "./app/store";
import AppRouter from "./common/Routing/AppRouter";
import mixpanel from "mixpanel-browser";
import { MIXPANEL_TOKEN } from "./ccf/Utils/Constants";
import "swiper/css/bundle";
import { BrowserRouter as Router } from "react-router-dom";

function source(){
  if (document.referrer.search('https?://(.*)google.([^/?]*)') === 0) {
    return 'Google';
  } else if (document.referrer.search('https?://(.*)bing.([^/?]*)') === 0) {
    return 'Bing';
  } else if (document.referrer.search('https?://(.*)yahoo.([^/?]*)') === 0) {
    return 'Yahoo';
  } else if (document.referrer.search('https?://(.*)facebook.([^/?]*)') === 0) {
    return 'Facebook';
  } else if (document.referrer.search('https?://(.*)twitter.([^/?]*)') === 0) {
    return 'Twitter';
  } else {
    return 'Other';
  }
}

function App() {
  mixpanel.init(MIXPANEL_TOKEN)
  mixpanel.register_once({"Source": source()});
  mixpanel.people.set_once({"Source": source()});
  return (
    <Provider store={store}>
      <div className="ccf-container">
        <Router>
          <AppRouter />
        </Router>
      </div>
    </Provider>
  );
}

export default App;

