import { Link, useNavigate } from "react-router-dom";
import shopicon from "../../../assets/disclaimerlogo.svg";
import AppHeader from "../Header/header";
import "./disclaimer.css";
import { ROUTES } from "../../Routing/Routing";
import mixpanel from "mixpanel-browser";
import { Box, Grid, Stack } from "@mui/material";
import bgImage from "../../../assets/disclaimer.jpg";
import bgImagelarge from "../../../assets/disclaimer_large.jpg";
import disclaimerLandscape from "../../../assets/disclaimer_landscape.png";

const Disclaimer = (props:any) => {
  let navigate = useNavigate();
  const navigateToProducts = async () => {
    //navigate(ROUTES.PREFERENCES, { state: false });
    props.handleClick()
  };


  return (
    <>
    <Box sx={{margin:"auto",
          maxWidth:"1366px"}}>
    <AppHeader
        loadQuestion={null}
        previousIdQuestion={null}
        isBackAllow={false}
      />
    </Box>
      
      {/* small screen */}
      <Box sx={{ display: { sm: "none", overflow: "scroll"  } }}>
        <Box
          sx={{
            background: `url(${bgImage})`,
            height: "100vh",
            padding: "15px",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover"
          }}
        >
          <Stack gap={1.5} sx={{overflowY:'auto',height:"90%"}} justifyContent="center">
            <Box>
              <p className="ccf-question-heading text-primary">Disclaimer:</p>
            </Box>
            <Box>
              <p className="disclaimer_desc">
                The information provided here is not intended to diagnose,
                treat, cure, or prevent any medical condition. It is intended
                solely for informational purposes and should not be used as a
                substitute for consultation with your healthcare provider. Each
                person's health and wellness needs are different and should be
                individually evaluated by a qualified healthcare provider.
                Follow the directions of your healthcare provider with regard to
                the use of your specific medications and treatment of your
                medical conditions.
              </p>
            </Box>
            <Box>
              <button className="btn-primary" onClick={navigateToProducts}>
                Continue
              </button>
              <button className="btn-secondary">
                {
                  <Link
                    to={ROUTES.ROOT}
                    style={{
                      color: "#CC0000",
                    }}
                  >
                    Cancel
                  </Link>
                }
              </button>
            </Box>
            <Box>
              <img src={shopicon} alt="logo" />
            </Box>
          </Stack>
        </Box>
      </Box>

      {/* medium to large */}
      <Box
        sx={{
          height: "100vh",
          display: { xs: "none", sm: "block", md: "none" },
          background: "#ffffff",
          margin:"auto",
          maxWidth:"1366px",
          border:"1px solid #eee"
        }}
      >
        <Box
          sx={{
            width: "100%",
            height: "45%",
            backgroundImage: `url(${bgImagelarge})`,
            backgroundSize: "cover",
          }}
        ></Box>
        <Stack
          gap={2}
          sx={{ padding: "15px", height: "55%", overflowY: "auto" }}
        >
          <Box>
            <p className="ccf-question-heading text-primary">Disclaimer:</p>
          </Box>
          <Box>
            <p className="disclaimer_desc">
              The information provided here is not intended to diagnose, treat,
              cure, or prevent any medical condition. It is intended solely for
              informational purposes and should not be used as a substitute for
              consultation with your healthcare provider. Each person's health
              and wellness needs are different and should be individually
              evaluated by a qualified healthcare provider. Follow the
              directions of your healthcare provider with regard to the use of
              your specific medications and treatment of your medical
              conditions.
            </p>
          </Box>
          <Box>
            <button className="btn-primary" onClick={navigateToProducts}>
              Continue
            </button>
            <button className="btn-secondary">
              {
                <Link
                  to={ROUTES.ROOT}
                  style={{
                    color: "#CC0000",
                  }}
                >
                  Cancel
                </Link>
              }
            </button>
          </Box>
          <Box>
            <img src={shopicon} alt="logo" />
          </Box>
        </Stack>
      </Box>
      {/* Landscape disclaimer */}
      <Box
        sx={{
          display: { xs: "none", sm: "none", md: "block" },
          background: "#ffffff",
          height: "100vh",
          margin:"auto",
          maxWidth:"1366px",
          border:"1px solid #eee"
        }}
      >
        <Grid container>
          <Grid item md={6}>
            <Box
              sx={{
                backgroundImage: `url(${disclaimerLandscape})`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                height: "100vh",
                width: "100%",
                backgroundPosition: "center",
              }}
            ></Box>
          </Grid>
          <Grid item md={6}>
            <Box
              sx={{
                height: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Stack sx={{ width: "75%" }} gap={2.5}>
                <Box>
                  <p className="ccf-question-heading text-primary">
                    Disclaimer:
                  </p>
                </Box>
                <Box>
                  <p className="disclaimer_desc">
                    The information provided here is not intended to diagnose,
                    treat, cure, or prevent any medical condition. It is
                    intended solely for informational purposes and should not be
                    used as a substitute for consultation with your healthcare
                    provider. Each person's health and wellness needs are
                    different and should be individually evaluated by a
                    qualified healthcare provider. Follow the directions of your
                    healthcare provider with regard to the use of your specific
                    medications and treatment of your medical conditions.
                  </p>
                </Box>
                <Box>
                  <button className="btn-primary" onClick={navigateToProducts}>
                    Continue
                  </button>
                  <button className="btn-secondary">
                    {
                      <Link
                        to={ROUTES.ROOT}
                        style={{
                          color: "#CC0000",
                        }}
                      >
                        Cancel
                      </Link>
                    }
                  </button>
                </Box>
                <Box>
                  <img src={shopicon} alt="logo" />
                </Box>
              </Stack>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default Disclaimer;
