import "./option.css";
import { useEffect } from "react";
import { Box, Checkbox, Radio } from "@mui/material";

import CoughIcon from "../../../assets/SymptomIcon/CCFIcon/cough.svg";
import ColdSoreIcon from "../../../assets/SymptomIcon/CCFIcon/coldSoar.svg";
import ChestIcon from "../../../assets/SymptomIcon/CCFIcon/chestIcon.svg";
import SoarThroatIcon from "../../../assets/SymptomIcon/CCFIcon/soarThroat.svg";
import FeverIcon from "../../../assets/SymptomIcon/CCFIcon/feverPain.svg";
import EarNoseIcon from "../../../assets/SymptomIcon/CCFIcon/earNose.svg";
import SneezingIcon from "../../../assets/SymptomIcon/CCFIcon/loss-smell.svg";
import ColdNFluIcon from "../../../assets/SymptomIcon/CCFIcon/coldNFlu.svg";

export default function MultiSelect(props: any) {
  useEffect(() => {}, [props.selectId]);
  function handleChange() {
    let isAdding = true;
    if (!props?.selectId?.includes(props?.optionId)) {
      isAdding = true;
    } else {
      isAdding = false;
    }
    props.handleClick(props.value, props.label, isAdding);
  }

  const ShowIcon = () => {
    let imageUrl = "";
    if (props.optionId === 6) {
      imageUrl = CoughIcon;
    } else if (props.optionId === 7) {
      imageUrl = ColdSoreIcon;
    } else if (props.optionId === 8) {
      imageUrl = ChestIcon;
    } else if (props.optionId === 9) {
      imageUrl = SoarThroatIcon;
    } else if (props.optionId === 10) {
      imageUrl = FeverIcon;
    } else if (props.optionId === 11) {
      imageUrl = EarNoseIcon;
    } else if (props.optionId === 12) {
      imageUrl = SneezingIcon;
    } else if (props.optionId === 13) {
      imageUrl = ColdNFluIcon;
    }
    return (
      <img
        src={imageUrl}
        alt="item-icon"
        style={{
          width: "40px",
          height: "40px",
          objectFit: "contain",
        }}
      />
    );
  };
  return (
    <>
     {props.showOption ? null : <Box
        key={props.optionId}
        className={`categoryItems ${props?.isSelected ? "item_active" : ""}`}
        onClick={() => handleChange()}
        sx={{ position: "relative", width: "48%", marginBottom:"10px", gap:"9px",padding:"6px 5px" }}
      >   
        {props.optionId !== 13 ? (
          <Checkbox
            checked={props?.isSelected}
            onChange={handleChange}
            sx={{
              color: "#BFBFBF",
              position: "absolute",
              top: 0,
              right: 0,
              "&.Mui-checked": {
                color: "#C00",
              },
            }}
          />
        ) : (
          <Radio
            checked={props?.isSelected}
            onChange={handleChange}
            sx={{
              color: "#BFBFBF",
              position: "absolute",
              top: 0,
              right: 0,
              "&.Mui-checked": {
                color: "#C00",
              },
            }}
          />
        )}
        <ShowIcon />
        <p style={{fontSize:"13px"}}>{props.label}</p>
      </Box>}
    </>
  );
}
