
export const BASE_API_URL = process.env.REACT_APP_API_URL || "";
export const GETPRODUCT_API_URL = "getProduct";
export const GETADDON_API_URL = "addonsProduct";
export const GETEECENDPOINT = "eyeEarData"
export const GETEECQUESTION = "getNextQuestion"
export const AUTH_TOKEN = process.env.REACT_APP_AUTH_TOKEN || ''

export const MULTI_SELECT_LABEL = "Select all that apply";
export const SINGLE_SELECT_LABEL = "Please Select One";
export const DEVCYCLE_ENV_KEY = process.env.REACT_APP_DEVCYCLE_ENV_KEY || ""
export const MIXPANEL_TOKEN = process.env.REACT_APP_MIXPANEL_TOKEN || ""

export {}