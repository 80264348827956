import { createSlice } from "@reduxjs/toolkit";
import { questions } from "./vitaminsQuestionnaire";

const initialState = {
  answer: {
    concern: "",
    concernType: "",
    frequency: "",
    symptoms: [],
    strength: "",
    productForm: "",
    type: "vitamins",
  },
  selectedOption: [0],
  visited: false,
};

const vitaminsSlice = createSlice({
  name: "vitamins",
  initialState,
  reducers: {
    setAnswer(state, action) {
      const concern = ["1", "2"];
      const concernType = ["3", "4", "5"];
      const frequency = ["6", "7", "27", "28"];
      const strength = ["17", "18", "19", "20"];
      const productForm = ["21", "22", "23", "24", "25", "26"];
      if (concern.includes(action.payload.id)) {
        state.answer.concern = action.payload.value;
        return;
      }
      if (concernType.includes(action.payload.id)) {
        state.answer.concernType = action.payload.value;
        return;
      }
      if (frequency.includes(action.payload.id)) {
        state.answer.frequency = action.payload.value;
        return;
      }
      if (action.payload.id === 3 || action.payload.id === 7) {
        console.log("multi symptom redux", action.payload);
        state.answer.symptoms = action.payload.value;
        return;
      }
      if (strength.includes(action.payload.id)) {
        state.answer.strength = action.payload.value;
        return;
      }
      if (productForm.includes(action.payload.id)) {
        state.answer.productForm = action.payload.value;
        return;
      }
    },
    setSelectedOption(state, action) {
      state.selectedOption.push(action.payload);
    },
    setUpdateSelectedOption(state) {
      state.selectedOption.pop();
    },
    resetState(state, action) {},
    updateState(state, action) {
      if (action.payload == 1) {
        state.answer.concernType = "";
      }
      if (action.payload === 3 || action.payload === 7) {
        state.visited = !state.visited;
      }
    },
    updateSymptoms(state) {
      state.answer.symptoms = [];
    },
    resetVitaminState(state) {
      state.answer = {
        concern: "",
        concernType: "",
        frequency: "",
        symptoms: [],
        strength: "",
        productForm: "",
        type: "vitamins",
      };
      state.selectedOption = [];
      state.visited = false;
    },
  },
});
export const {
  setAnswer,
  setSelectedOption,
  setUpdateSelectedOption,
  updateState,
  updateSymptoms,
  resetVitaminState,
} = vitaminsSlice.actions;
export default vitaminsSlice.reducer;
